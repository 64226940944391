.shapes-wrapper {
	padding:0 0 0 12%;
	max-width:50%;
	.display-flex;
	.flex(1 0 50%);
}
.join-team {
	max-width:314px;
	height:200px;
	margin:0 auto;
	overflow:hidden;
	.flex(1 0 auto);
	.display-flex;
	.align-items(flex-end);
	.justify-content(space-between);
	i:before,
	i:after {
		content:'';
		display:block;
	}
	i:before {
		margin:0 auto 12px;
		width:56px;
		height:56px;
		.transform-origin(center);
		.border-radius(50%);
	}
	i:after {
	    .transition(all .6s ease);
	}
	.orange {
		&:before,
		&:after { background-color:@red; }
		&:after {
			width:78px;
			height:86px;
			display:block;
		}
	}
	.icon-caret {
		text-align:center;
		&:after { background-color:@blue; }
		&:before {
			content: @icon-caret;
			color:@blue;
			font-size:35px;
			line-height:35px;
			overflow:visible;
			width:auto;
			height:auto;
		}
		&:after {
			width:66px;
			height:151px;
		}
	}
	.green {
		&:before,
		&:after { background-color:@green; }
		&:after {
			width:78px;
			height:121px;
		}
	}
	.yellow {
		&:before,
		&:after { background-color:@yellow; }
		&:after {
			width:56px;
			height:103px;
		}
	}
	&.active {
	    i:before {
	        .animation(bounce .5s linear 3);
	    }
	    i.icon-caret:before {
	        .animation(pulse .5s ease 3);
	    }
	}
}

.intelligent {
	min-width:322px;
	max-width:322px;
	height:200px;
	position:relative;
	.flex(1 0 auto);
	i.icon-caret {
		&:before {
			font-size:41px;
			color:@blue;
		}
		&:first-child { .position(absolute, 0, auto, auto, 24px); }
		&:nth-child(2) { .position(absolute, auto, 0, 22px); }
	}
	.green, .yellow, .orange {
		&:before {
			content:'';
			display:block;
			width:56px;
			height:56px;
			.border-radius(50%);
		}
	}
	.orange:before {
		background-color:@red;
		.position(absolute, 97px, auto, auto, 0);
	}
	.yellow:before {
		background-color:@yellow;
		.position(absolute, 41px, 106px);
	}
	.green:before {
		background-color:@green;
		.border-radius(0);
		.position(absolute, auto, auto, 0, 106px);
	}
	&.active {
	    i.icon-caret {
	        .animation(pulse .5s ease 3);
	    }
	}
}

.top1p {
	text-align:center;
	min-width:250px;
	max-width:250px;
	height:204px;
	overflow:hidden;
	margin:0 auto;
	.flex(1 0 auto);
	i { .translate3d(0, -250px, 0); }
	i.icon-caret {
	    display:block;
	    text-align:center;
		font-size:34px;
		&:before { color:@blue; }
	}
	i:not(.icon-caret) {
		display:block;
		margin:12px auto 0;
		border-left:25px solid transparent;
		border-right:25px solid transparent;
		border-bottom:44px solid transparent;
	}
	i.green {
		width:122px;
		border-bottom-color:@green;
	}
	i.yellow {
		width:186px;
		border-bottom-color:@yellow;
	}
	i.orange {
		width: 246px;
		border-bottom-color:@red;
	}
	&.active {
	    i {
	        &.orange { .animation(slideInDown .5s ease forwards); }
	        &.yellow { .animation(slideInDown .5s ease .4s forwards); }
	        &.green { .animation(slideInDown .5s ease .9s forwards); }
	        &.icon-caret { .animation(slideInDown .5s 1.4s ease forwards); }
	    }
	}
}

.flexible-contracts {
	min-width:365px;
	max-width:365px;
	overflow:hidden;
	height:200px;
	position:relative;
	margin:0 auto;
	.display-flex;
	.align-items(flex-end);
	.justify-content(space-between);
	i, i:after {
	    .transition(all .5s ease);
	}
	.yellow {
		display:block;
		width:94px;
		height:0;
		background-color:@yellow;
	}
	i.icon-caret {
		text-align:center;
		display:block;
		&:before {
			color:@blue;
			font-size:30px;
			display:block;
			text-align:center;
			.translate3d(0, -250px, 0);
		}
		&:after {
			content:'';
			display:block;
			width:122px;
			height:0;
			margin:10px 0 0;
			background-color:@blue;
		}
	}
	.green {
		width:70px;
		height:0;
		background-color:@green;
	}
	.orange {
		width:48px;
		height:0;
		background-color:@red;
	}
	&.active {
	    i.icon-caret:before { .animation(slideInDown .5s ease .5s forwards); }
	    i.icon-caret:after { height:161px; }
	    .green { height:63px; }
	    .orange { height:113px; }
	    .yellow { height:122px; }
	}
}
.right-technology {
	position:relative;
	min-width:290px;
	max-width:290px;
	height:200px;
	margin:0 auto;
	.icon-caret {
		font-size:50px;
		margin:-25px -48px 0 0;
		.position(absolute, 50%, 50%);
		&:before { color:@blue; }
	}
	i:not(.icon-caret) {
		display:block;
		.border-radius(50%);
	}
	.yellow {
		width:50px;
		height:50px;
		background-color:@yellow;
		.position(absolute, 0, auto, auto, 72px);
	}
	.green {
		width:40px;
		height:40px;
		background-color:@green;
		.position(absolute, auto, auto, 30px, 0);
	}
	.orange {
		width:44px;
		height:44px;
		background-color:@red;
		.position(absolute, 60px, 0);
	}
	.blue {
		width:25px;
		height:25px;
		background-color:@blue;
		.position(absolute, auto, 57px, 0);
	}
	&.active {
	    .icon-caret { .animation(pulse .5s ease 3); }
	}
	.top1p i.orange {
        width: 246px;
    }
}

.keyframes(bounce, {
    0% { .translate3d(0, 0, 0); }
    50% { .translate3d(0, 2px, 0); }
    100% { .translate3d(0, 0, 0); }
});
.keyframes(showIn, {
    from { opacity:0; }
    to { opacity:1; }
});
.keyframes(slideInDown, {
    from { .translate3d(0, -250px, 0); }
    to { .translate3d(0, 0, 0); }
});
.keyframes(zoomIn, {
    from { .transform-origin(center center); }
    to { .transform(scale3d(1.1, 1.1, 1.1)); }
});