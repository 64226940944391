@white: #fff;
@black: #000;
@blue: #00a1e1;

@truelocal:#2d2d58;
@mingl:#fd8023;
@gocus:#606062;
@mealpal:#0a1a72;
@thechive:#66b245;
@booodl:#6b519e;

@kpi-orange: #f6c304;
@kpi-red: #ff8570;
@kpi-blue: #33d8c4;

@yellow: #f5c300;
@green: #32dac4;
@green2: #66b245;
@red: #ff8571;

@icon: 'upstack';
@circular: 'Circular', Helvetica, Roboto, Arial, sans-serif;
@proxima: 'ProximaNova', Helvetica, Roboto, Arial, sans-serif;
@nunito: 'Nunito Sans', sans-serif;

@icomoon-font-path: "../fonts/icon";
@circular-font-path: "../fonts/circular";
@proxima-font-path: "../fonts/proxima";

@screen-xs:                  480px;
@screen-xs-min:              @screen-xs;
@screen-phone:               @screen-xs-min;

@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
@screen-tablet:              @screen-sm-min;

@screen-md:                  992px;
@screen-md-min:              @screen-md;
@screen-desktop:             @screen-md-min;

@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
@screen-lg-desktop:          @screen-lg-min;

@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);

@grid-columns:              12;
@grid-gutter-width:         30px;
@grid-float-breakpoint:     @screen-sm-min;
@grid-float-breakpoint-max: (@grid-float-breakpoint - 1);

@container-tablet:             (720px + @grid-gutter-width);
@container-sm:                 @container-tablet;

@container-desktop:            (940px + @grid-gutter-width);
@container-md:                 @container-desktop;

@container-large-desktop:      (1140px + @grid-gutter-width);
@container-lg:                 @container-large-desktop;

@icon-check: "\eac7";
@icon-chat: "\eac3";
@icon-contact1: "\eac4";
@icon-web: "\eac5";
@icon-clock1: "\eac6";

@icon-logo: "\e900";
@icon-phone: "\e901";
@icon-lock: "\e902";
@icon-facebook: "\e903";
@icon-twitter: "\e904";
@icon-instagram: "\e905";
@icon-menu: "\e906";
@icon-close: "\e907";
@icon-cog-empty-p1: "\e908";
@icon-cog-empty-p2: "\e909";
@icon-cog: "\e90a";


// Advantage page icons
@icon-price-tag: "\eabe";
@icon-database: "\ea82";
@icon-db: "\ea82";
@icon-server: "\ea82";
@icon-host: "\ea82";
@icon-storage: "\ea82";
@icon-save: "\ea82";
@icon-datecenter: "\ea82";
@icon-display: "\ea83";
@icon-screen: "\ea83";
@icon-monitor: "\ea83";
@icon-computer: "\ea83";
@icon-desktop: "\ea83";
@icon-pc: "\ea83";
@icon-quotes-right: "\ea7c";
@icon-quotes-left: "\ea7d";
@icon-ldquo: "\ea7d";
@icon-facebook: "\e903";
@icon-twitter: "\e904";
@icon-instagram: "\e905";
@icon-caret: "\eaba";
@icon-top1-talent-p1: "\ea9f";
@icon-top1-talent-p2: "\eaa0";
@icon-top1-talent-p3: "\eaa1";
@icon-top1-talent-p4: "\eaa2";
@icon-intelligent-matching-p1: "\eaa3";
@icon-intelligent-matching-p2: "\eaa4";
@icon-intelligent-matching-p3: "\eaa5";
@icon-intelligent-matching-p4: "\eaa6";
@icon-intelligent-matching-p5: "\eaa7";
@icon-flexible-contracts-p1: "\eaa8";
@icon-flexible-contracts-p2: "\eaa9";
@icon-flexible-contracts-p3: "\eaaa";
@icon-flexible-contracts-p4: "\eaab";
@icon-flexible-contracts-p5: "\eaac";
@icon-the-right-technology-p1: "\eaad";
@icon-the-right-technology-p2: "\eaae";
@icon-the-right-technology-p3: "\eaaf";
@icon-the-right-technology-p4: "\eab0";
@icon-the-right-technology-p5: "\eab1";
@icon-we-join-your-team-p1: "\eab2";
@icon-we-join-your-team-p2: "\eab3";
@icon-we-join-your-team-p3: "\eab4";
@icon-we-join-your-team-p4: "\eab5";
@icon-we-join-your-team-p5: "\eab6";
@icon-we-join-your-team-p6: "\eab7";
@icon-we-join-your-team-p7: "\eab8";
@icon-we-join-your-team-p8: "\eab9";
@icon-truelocal0: "\ea9c";
@icon-truelocal: "\ea9d";
@icon-intercom: "\eac0";
@icon-unfold: "\eac1";
@icon-Juul: "\eac2";
@icon-orchestra: "\ea97";
@icon-mealpal: "\ea98";
@icon-winc: "\ea99";
@icon-thechive: "\ea9a";
@icon-invision: "\ea9b";
@icon-apartment: "\ea85";
@icon-bicycle: "\ea86";
@icon-briefcase: "\ea87";
@icon-bug: "\ea88";
@icon-bullhorn: "\ea89";
@icon-car: "\ea8a";
@icon-cart: "\ea8b";
@icon-cloud: "\ea8c";
@icon-coffee-cup: "\ea8d";
@icon-construction: "\ea8e";
@icon-diamond: "\ea8f";
@icon-keyboard: "\ea90";
@icon-laptop: "\ea91";
@icon-pencil: "\ea92";
@icon-rocket: "\ea93";
@icon-smartphone: "\ea94";
@icon-store: "\ea95";
@icon-thumbs-up: "\ea96";
@icon-logo-upstack: "\ea84";
@icon-work-p1: "\ea69";
@icon-work-p2: "\ea6a";
@icon-work-p3: "\ea6b";
@icon-work-p4: "\ea6c";
@icon-work-p5: "\ea6d";
@icon-work-p6: "\ea6e";
@icon-work-p7: "\ea6f";
@icon-work-p8: "\ea70";
@icon-work-p9: "\ea71";
@icon-work-p10: "\ea72";
@icon-work-p11: "\ea73";
@icon-work-p12: "\ea74";
@icon-work-p13: "\ea75";
@icon-work-p14: "\ea76";
@icon-work-p15: "\ea77";
@icon-boarding-p1: "\e90b";
@icon-boarding-p2: "\e90c";
@icon-boarding-p3: "\e90d";
@icon-boarding-p4: "\e90e";
@icon-boarding-p5: "\e90f";
@icon-boarding-p6: "\e910";
@icon-boarding-p7: "\e911";
@icon-boarding-p8: "\e912";
@icon-boarding-p9: "\e913";
@icon-boarding-p10: "\e914";
@icon-boarding-p11: "\e915";
@icon-boarding-p12: "\e916";
@icon-boarding-p13: "\e917";
@icon-boarding-p14: "\e918";
@icon-boarding-p15: "\e919";
@icon-boarding-p16: "\e91a";
@icon-boarding-p17: "\e91b";
@icon-boarding-p18: "\e91c";
@icon-boarding-p19: "\e91d";
@icon-boarding-p20: "\e91e";
@icon-boarding-p21: "\e91f";
@icon-boarding-p22: "\e920";
@icon-boarding-p23: "\e921";
@icon-boarding-p24: "\e922";
@icon-boarding-p25: "\e923";
@icon-boarding-p26: "\e924";
@icon-comunication-p1: "\e925";
@icon-comunication-p2: "\e926";
@icon-comunication-p3: "\e927";
@icon-comunication-p4: "\e928";
@icon-comunication-p5: "\e929";
@icon-comunication-p6: "\e92a";
@icon-comunication-p7: "\e92b";
@icon-comunication-p8: "\e92c";
@icon-comunication-p9: "\e92d";
@icon-comunication-p10: "\e92e";
@icon-comunication-p11: "\e92f";
@icon-comunication-p12: "\e930";
@icon-comunication-p13: "\e931";
@icon-comunication-p14: "\e932";
@icon-comunication-p15: "\e933";
@icon-cultured-p1: "\e934";
@icon-cultured-p2: "\e935";
@icon-cultured-p3: "\e936";
@icon-cultured-p4: "\e937";
@icon-cultured-p5: "\e938";
@icon-cultured-p6: "\e939";
@icon-cultured-p7: "\e93a";
@icon-cultured-p8: "\e93b";
@icon-cultured-p9: "\e93c";
@icon-cultured-p10: "\e93d";
@icon-cultured-p11: "\e93e";
@icon-cultured-p12: "\e93f";
@icon-cultured-p13: "\e940";
@icon-cultured-p14: "\e941";
@icon-cultured-p15: "\e942";
@icon-cultured-p16: "\e943";
@icon-cultured-p17: "\e944";
@icon-cultured-p18: "\e945";
@icon-cultured-p19: "\e946";
@icon-cultured-p20: "\e947";
@icon-cultured-p21: "\e948";
@icon-cultured-p22: "\e949";
@icon-cultured-p23: "\e94a";
@icon-cultured-p24: "\e94b";
@icon-cultured-p25: "\e94c";
@icon-cultured-p26: "\e94d";
@icon-cultured-p27: "\e94e";
@icon-cultured-p28: "\e94f";
@icon-cultured-p29: "\e950";
@icon-cultured-p30: "\e951";
@icon-cultured-p31: "\e952";
@icon-cultured-p32: "\e953";
@icon-cultured-p33: "\e954";
@icon-cultured-p34: "\e955";
@icon-cultured-p35: "\e956";
@icon-cultured-p36: "\e957";
@icon-cultured-p37: "\e958";
@icon-cultured-p38: "\e959";
@icon-cultured-p39: "\e95a";
@icon-cultured-p40: "\e95b";
@icon-cultured-p41: "\e95c";
@icon-cultured-p42: "\e95d";
@icon-cultured-p43: "\e95e";
@icon-cultured-p44: "\e95f";
@icon-cultured-p45: "\e960";
@icon-cultured-p46: "\e961";
@icon-cultured-p47: "\e962";
@icon-cultured-p48: "\e963";
@icon-cultured-p49: "\e964";
@icon-cultured-p50: "\e965";
@icon-cultured-p51: "\e966";
@icon-cultured-p52: "\e967";
@icon-cultured-p53: "\e968";
@icon-cultured-p54: "\e969";
@icon-cultured-p55: "\e96a";
@icon-cultured-p56: "\e96b";
@icon-cultured-p57: "\e96c";
@icon-cultured-p58: "\e96d";
@icon-cultured-p59: "\e96e";
@icon-cultured-p60: "\e96f";
@icon-cultured-p61: "\e970";
@icon-cultured-p62: "\e971";
@icon-cultured-p63: "\e972";
@icon-cultured-p64: "\e973";
@icon-cultured-p65: "\e974";
@icon-cultured-p66: "\e975";
@icon-cultured-p67: "\e976";
@icon-cultured-p68: "\e977";
@icon-demand-p1: "\e978";
@icon-demand-p2: "\e979";
@icon-demand-p3: "\e97a";
@icon-demand-p4: "\e97b";
@icon-demand-p5: "\e97c";
@icon-demand-p6: "\e97d";
@icon-demand-p7: "\e97e";
@icon-demand-p8: "\e97f";
@icon-demand-p9: "\e980";
@icon-fish-p1: "\e981";
@icon-fish-p2: "\e982";
@icon-fish-p3: "\e983";
@icon-fish-p4: "\e984";
@icon-fish-p5: "\e985";
@icon-fish-p6: "\e986";
@icon-fish-p7: "\e987";
@icon-fish-p8: "\e988";
@icon-fish-p9: "\e989";
@icon-fish-p10: "\e98a";
@icon-fish-p11: "\e98b";
@icon-fish-p12: "\e98c";
@icon-fish-p13: "\e98d";
@icon-fish-p14: "\e98e";
@icon-fish-p15: "\e98f";
@icon-fish-p16: "\e990";
@icon-fish-p17: "\e991";
@icon-fish-p18: "\e992";
@icon-fish-p19: "\e993";
@icon-fish-p20: "\e994";
@icon-fish-p21: "\e995";
@icon-fish-p22: "\e996";
@icon-fish-p23: "\e997";
@icon-fish-p24: "\e998";
@icon-fish-p25: "\e999";
@icon-fish-p26: "\e99a";
@icon-fish-p27: "\e99b";
@icon-happy-p1: "\e99c";
@icon-happy-p2: "\e99d";
@icon-happy-p3: "\e99e";
@icon-happy-p4: "\e99f";
@icon-happy-p5: "\e9a0";
@icon-happy-p6: "\e9a1";
@icon-happy-p7: "\e9a2";
@icon-happy-p8: "\e9a3";
@icon-happy-p9: "\e9a4";
@icon-happy-p10: "\e9a5";
@icon-happy-p11: "\e9a6";
@icon-happy-p12: "\e9a7";
@icon-happy-p13: "\e9a8";
@icon-happy-p14: "\e9a9";
@icon-happy-p15: "\e9aa";
@icon-happy-p16: "\e9ab";
@icon-happy-p17: "\e9ac";
@icon-happy-p18: "\e9ad";
@icon-happy-p19: "\e9ae";
@icon-happy-p20: "\e9af";
@icon-happy-p21: "\e9b0";
@icon-happy-p22: "\e9b1";
@icon-happy-p23: "\e9b2";
@icon-happy-p24: "\e9b3";
@icon-happy-p25: "\e9b4";
@icon-happy-p26: "\e9b5";
@icon-happy-p27: "\e9b6";
@icon-happy-p28: "\e9b7";
@icon-happy-p29: "\e9b8";
@icon-happy-p30: "\e9b9";
@icon-happy-p31: "\e9ba";
@icon-happy-p32: "\e9bb";
@icon-integration: "\e9bc";
@icon-job-p1: "\e9bd";
@icon-job-p2: "\e9be";
@icon-job-p3: "\e9bf";
@icon-job-p4: "\e9c0";
@icon-job-p5: "\e9c1";
@icon-job-p6: "\e9c2";
@icon-job-p7: "\e9c3";
@icon-job-p8: "\e9c4";
@icon-job-p9: "\e9c5";
@icon-job-p10: "\e9c6";
@icon-job-p11: "\e9c7";
@icon-job-p12: "\e9c8";
@icon-job-p13: "\e9c9";
@icon-job-p14: "\e9ca";
@icon-job-p15: "\e9cb";
@icon-job-p16: "\e9cc";
@icon-job-p17: "\e9cd";
@icon-job-p18: "\e9ce";
@icon-job-p19: "\e9cf";
@icon-job-p20: "\e9d0";
@icon-job-p21: "\e9d1";
@icon-job-p22: "\e9d2";
@icon-job-p23: "\e9d3";
@icon-job-p24: "\e9d4";
@icon-job-p25: "\e9d5";
@icon-job-p26: "\e9d6";
@icon-job-p27: "\e9d7";
@icon-job-p28: "\e9d8";
@icon-job-p29: "\e9d9";
@icon-job-p30: "\e9da";
@icon-job-p31: "\e9db";
@icon-job-p32: "\e9dc";
@icon-job-p33: "\e9dd";
@icon-job-p34: "\e9de";
@icon-job-p35: "\e9df";
@icon-job-p36: "\e9e0";
@icon-job-p37: "\e9e1";
@icon-job-p38: "\e9e2";
@icon-job-p39: "\e9e3";
@icon-job-p40: "\e9e4";
@icon-job-p41: "\e9e5";
@icon-job-p42: "\e9e6";
@icon-job-p43: "\e9e7";
@icon-job-p44: "\e9e8";
@icon-job-p45: "\e9e9";
@icon-job-p46: "\e9ea";
@icon-job-p47: "\e9eb";
@icon-job-p48: "\e9ec";
@icon-lose-p1: "\e9ed";
@icon-lose-p2: "\e9ee";
@icon-lose-p3: "\e9ef";
@icon-lose-p4: "\e9f0";
@icon-lose-p5: "\e9f1";
@icon-lose-p6: "\e9f2";
@icon-lose-p7: "\e9f3";
@icon-lose-p8: "\e9f4";
@icon-lose-p9: "\e9f5";
@icon-lose-p10: "\e9f6";
@icon-lose-p11: "\e9f7";
@icon-lose-p12: "\e9f8";
@icon-lose-p13: "\e9f9";
@icon-lose-p14: "\e9fa";
@icon-lose-p15: "\e9fb";
@icon-lose-p16: "\e9fc";
@icon-lose-p17: "\e9fd";
@icon-lose-p18: "\e9fe";
@icon-lose-p19: "\e9ff";
@icon-lose-p20: "\ea00";
@icon-lose-p21: "\ea01";
@icon-lose-p22: "\ea02";
@icon-lose-p23: "\ea03";
@icon-lose-p24: "\ea04";
@icon-lose-p25: "\ea05";
@icon-management-p1: "\ea06";
@icon-management-p2: "\ea07";
@icon-management-p3: "\ea08";
@icon-management-p4: "\ea09";
@icon-management-p5: "\ea0a";
@icon-management-p6: "\ea0b";
@icon-management-p7: "\ea0c";
@icon-management-p8: "\ea0d";
@icon-management-p9: "\ea0e";
@icon-management-p10: "\ea0f";
@icon-management-p11: "\ea10";
@icon-management-p12: "\ea11";
@icon-management-p13: "\ea12";
@icon-management-p14: "\ea13";
@icon-management-p15: "\ea14";
@icon-management-p16: "\ea15";
@icon-management-p17: "\ea16";
@icon-management-p18: "\ea17";
@icon-management-p19: "\ea18";
@icon-quality-p1: "\ea19";
@icon-quality-p2: "\ea1a";
@icon-quality-p3: "\ea1b";
@icon-quality-p4: "\ea1c";
@icon-quality-p5: "\ea1d";
@icon-quality-p6: "\ea1e";
@icon-quality-p7: "\ea1f";
@icon-quality-p8: "\ea20";
@icon-quality-p9: "\ea21";
@icon-quality-p10: "\ea22";
@icon-senior-p1: "\ea23";
@icon-senior-p2: "\ea24";
@icon-senior-p3: "\ea25";
@icon-senior-p4: "\ea26";
@icon-senior-p5: "\ea27";
@icon-senior-p6: "\ea28";
@icon-senior-p7: "\ea29";
@icon-senior-p8: "\ea2a";
@icon-senior-p9: "\ea2b";
@icon-senior-p10: "\ea2c";
@icon-senior-p11: "\ea2d";
@icon-senior-p12: "\ea2e";
@icon-senior-p13: "\ea2f";
@icon-senior-p14: "\ea30";
@icon-senior-p15: "\ea31";
@icon-senior-p16: "\ea32";
@icon-senior-p17: "\ea33";
@icon-senior-p18: "\ea34";
@icon-senior-p19: "\ea35";
@icon-senior-p20: "\ea36";
@icon-senior-p21: "\ea37";
@icon-senior-p22: "\ea38";
@icon-senior-p23: "\ea39";
@icon-senior-p24: "\ea3a";
@icon-senior-p25: "\ea3b";
@icon-senior-p26: "\ea3c";
@icon-senior-p27: "\ea3d";
@icon-senior-p28: "\ea3e";
@icon-senior-p29: "\ea3f";
@icon-senior-p30: "\ea40";
@icon-tide-p1: "\ea41";
@icon-tide-p2: "\ea42";
@icon-tide-p3: "\ea43";
@icon-tide-p4: "\ea44";
@icon-tide-p5: "\ea45";
@icon-tide-p6: "\ea46";
@icon-tide-p7: "\ea47";
@icon-tide-p8: "\ea48";
@icon-tide-p9: "\ea49";
@icon-tide-p10: "\ea4a";
@icon-tide-p11: "\ea4b";
@icon-tide-p12: "\ea4c";
@icon-tide-p13: "\ea4d";
@icon-tide-p14: "\ea4e";
@icon-tide-p15: "\ea4f";
@icon-tide-p16: "\ea50";
@icon-tide-p17: "\ea51";
@icon-tide-p18: "\ea52";
@icon-tide-p19: "\ea53";
@icon-tide-p20: "\ea54";
@icon-tide-p21: "\ea55";
@icon-tide-p22: "\ea56";
@icon-tide-p23: "\ea57";
@icon-tide-p24: "\ea58";
@icon-tide-p25: "\ea59";
@icon-tide-p26: "\ea5a";
@icon-tide-p27: "\ea5b";
@icon-tide-p28: "\ea5c";
@icon-tide-p29: "\ea5d";
@icon-tide-p30: "\ea5e";
@icon-tide-p31: "\ea5f";
@icon-tide-p32: "\ea60";
@icon-tide-p33: "\ea61";
@icon-tide-p34: "\ea62";
@icon-tide-p35: "\ea63";
@icon-tide-p36: "\ea64";
@icon-tide-p37: "\ea65";
@icon-tide-p38: "\ea66";
@icon-tide-p39: "\ea67";
@icon-tide-p40: "\ea68";
@icon-cog-empty-p1: "\e908";
@icon-cog-empty-p2: "\e909";
@icon-cog: "\e90a";
@icon-logo: "\e900";
@icon-info-large: "\ea9e";
@icon-check_box_outline_blank: "\ea80";
@icon-check_box: "\ea81";
@icon-keyboard_arrow_down: "\ea7e";
@icon-arrow-down: "\ea7e";
@icon-arrow_back: "\ea7b";
@icon-arrow-left: "\ea7b";
@icon-close2: "\e907";
@icon-menu: "\e906";
@icon-plus: "\eabf";
@icon-heart: "\eabb";
@icon-clock: "\eabc";
@icon-location-pin: "\eabd";
@icon-help: "\ea7a";
@icon-lock: "\e902";
@icon-cancel: "\ea78";
@icon-close: "\ea78";
@icon-checkmark: "\ea79";
@icon-tick: "\ea79";
@icon-phone: "\e901";
@icon-contact: "\e901";
@icon-hourglass-2: "\ea7f";
@icon-hourglass-half: "\ea7f";
@icon-intercom: "\eac0";
@icon-unfold: "\eac1";
@icon-juul: "\eac2";
