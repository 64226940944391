@font-face {
	font-family: 'alttab';
	src:  url('@{icomoon-font-path}/alttab.eot?3oclla');
	src:  url('@{icomoon-font-path}/alttab.eot?3oclla#iefix') format('embedded-opentype'),
	url('@{icomoon-font-path}/alttab.woff2?3oclla') format('woff2'),
	url('@{icomoon-font-path}/alttab.ttf?3oclla') format('truetype'),
	url('@{icomoon-font-path}/alttab.woff?3oclla') format('woff'),
	url('@{icomoon-font-path}/alttab.svg?3oclla#alttab') format('svg');
	font-display: swap;
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"], [class^="icon-"] span, [class^="icon-"] i {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'alttab' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-check {
	&:before {
		content: @icon-check;
	}
}
.icon-chat {
	&:before {
		content: @icon-chat;
	}
}
.icon-contact1 {
	&:before {
		content: @icon-contact1;
	}
}
.icon-web {
	&:before {
		content: @icon-web;
	}
}
.icon-clock1 {
	&:before {
		content: @icon-clock1;
	}
}
.icon-logo {
	&:before {
		content: @icon-logo;
	}
}
.icon-phone {
	&:before {
		content: @icon-phone;
	}
}
.icon-lock {
	&:before {
		content: @icon-lock;
	}
}
.icon-facebook {
	&:before {
		content: @icon-facebook;
	}
}
.icon-twitter {
	&:before {
		content: @icon-twitter;
	}
}
.icon-instagram {
	&:before {
		content: @icon-instagram;
	}
}
.icon-menu {
	&:before {
		content: @icon-menu;
	}
}
.icon-close {
	&:before {
		content: @icon-close;
	}
}

.icon-header {
	* {
		font-family:@icon;
		font-style:normal;
	}
}
.icon-cog-empty .p1 {
	&:before {
		content: @icon-cog-empty-p1;
		color: rgb(255, 255, 255);
	}
}
.icon-cog-empty .p2 {
	&:before {
		content: @icon-cog-empty-p2;
		margin-left: -1em;
		color: rgb(0, 0, 0);
	}
}
.icon-cog {
	&:before {
		content: @icon-cog;
	}
}


// Adantage page icons
.icon-boarding .p1 {
	&:before {
		content: @icon-boarding-p1;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p2 {
	&:before {
		content: @icon-boarding-p2;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-boarding .p3 {
	&:before {
		content: @icon-boarding-p3;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p4 {
	&:before {
		content: @icon-boarding-p4;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-boarding .p5 {
	&:before {
		content: @icon-boarding-p5;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p6 {
	&:before {
		content: @icon-boarding-p6;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p7 {
	&:before {
		content: @icon-boarding-p7;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-boarding .p8 {
	&:before {
		content: @icon-boarding-p8;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p9 {
	&:before {
		content: @icon-boarding-p9;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-boarding .p10 {
	&:before {
		content: @icon-boarding-p10;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p11 {
	&:before {
		content: @icon-boarding-p11;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-boarding .p12 {
	&:before {
		content: @icon-boarding-p12;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p13 {
	&:before {
		content: @icon-boarding-p13;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-boarding .p14 {
	&:before {
		content: @icon-boarding-p14;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p15 {
	&:before {
		content: @icon-boarding-p15;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p16 {
	&:before {
		content: @icon-boarding-p16;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p17 {
	&:before {
		content: @icon-boarding-p17;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p18 {
	&:before {
		content: @icon-boarding-p18;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p19 {
	&:before {
		content: @icon-boarding-p19;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p20 {
	&:before {
		content: @icon-boarding-p20;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p21 {
	&:before {
		content: @icon-boarding-p21;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p22 {
	&:before {
		content: @icon-boarding-p22;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p23 {
	&:before {
		content: @icon-boarding-p23;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p24 {
	&:before {
		content: @icon-boarding-p24;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p25 {
	&:before {
		content: @icon-boarding-p25;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-boarding .p26 {
	&:before {
		content: @icon-boarding-p26;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-comunication .p1 {
	&:before {
		content: @icon-comunication-p1;
		color: rgb(255, 255, 255);
	}
}
.icon-comunication .p2 {
	&:before {
		content: @icon-comunication-p2;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-comunication .p3 {
	&:before {
		content: @icon-comunication-p3;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-comunication .p4 {
	&:before {
		content: @icon-comunication-p4;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-comunication .p5 {
	&:before {
		content: @icon-comunication-p5;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-comunication .p6 {
	&:before {
		content: @icon-comunication-p6;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-comunication .p7 {
	&:before {
		content: @icon-comunication-p7;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-comunication .p8 {
	&:before {
		content: @icon-comunication-p8;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-comunication .p9 {
	&:before {
		content: @icon-comunication-p9;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-comunication .p10 {
	&:before {
		content: @icon-comunication-p10;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-comunication .p11 {
	&:before {
		content: @icon-comunication-p11;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-comunication .p12 {
	&:before {
		content: @icon-comunication-p12;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-comunication .p13 {
	&:before {
		content: @icon-comunication-p13;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-comunication .p14 {
	&:before {
		content: @icon-comunication-p14;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-comunication .p15 {
	&:before {
		content: @icon-comunication-p15;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p1 {
	&:before {
		content: @icon-cultured-p1;
		color: rgb(231, 237, 250);
	}
}
.icon-cultured .p2 {
	&:before {
		content: @icon-cultured-p2;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-cultured .p3 {
	&:before {
		content: @icon-cultured-p3;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-cultured .p4 {
	&:before {
		content: @icon-cultured-p4;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-cultured .p5 {
	&:before {
		content: @icon-cultured-p5;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-cultured .p6 {
	&:before {
		content: @icon-cultured-p6;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-cultured .p7 {
	&:before {
		content: @icon-cultured-p7;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-cultured .p8 {
	&:before {
		content: @icon-cultured-p8;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-cultured .p9 {
	&:before {
		content: @icon-cultured-p9;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-cultured .p10 {
	&:before {
		content: @icon-cultured-p10;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p11 {
	&:before {
		content: @icon-cultured-p11;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p12 {
	&:before {
		content: @icon-cultured-p12;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p13 {
	&:before {
		content: @icon-cultured-p13;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p14 {
	&:before {
		content: @icon-cultured-p14;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p15 {
	&:before {
		content: @icon-cultured-p15;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p16 {
	&:before {
		content: @icon-cultured-p16;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p17 {
	&:before {
		content: @icon-cultured-p17;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p18 {
	&:before {
		content: @icon-cultured-p18;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p19 {
	&:before {
		content: @icon-cultured-p19;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p20 {
	&:before {
		content: @icon-cultured-p20;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p21 {
	&:before {
		content: @icon-cultured-p21;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p22 {
	&:before {
		content: @icon-cultured-p22;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-cultured .p23 {
	&:before {
		content: @icon-cultured-p23;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p24 {
	&:before {
		content: @icon-cultured-p24;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p25 {
	&:before {
		content: @icon-cultured-p25;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p26 {
	&:before {
		content: @icon-cultured-p26;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p27 {
	&:before {
		content: @icon-cultured-p27;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p28 {
	&:before {
		content: @icon-cultured-p28;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p29 {
	&:before {
		content: @icon-cultured-p29;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p30 {
	&:before {
		content: @icon-cultured-p30;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p31 {
	&:before {
		content: @icon-cultured-p31;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p32 {
	&:before {
		content: @icon-cultured-p32;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p33 {
	&:before {
		content: @icon-cultured-p33;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p34 {
	&:before {
		content: @icon-cultured-p34;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p35 {
	&:before {
		content: @icon-cultured-p35;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p36 {
	&:before {
		content: @icon-cultured-p36;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p37 {
	&:before {
		content: @icon-cultured-p37;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p38 {
	&:before {
		content: @icon-cultured-p38;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p39 {
	&:before {
		content: @icon-cultured-p39;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p40 {
	&:before {
		content: @icon-cultured-p40;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p41 {
	&:before {
		content: @icon-cultured-p41;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p42 {
	&:before {
		content: @icon-cultured-p42;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p43 {
	&:before {
		content: @icon-cultured-p43;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p44 {
	&:before {
		content: @icon-cultured-p44;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p45 {
	&:before {
		content: @icon-cultured-p45;
		margin-left: -1em;
		color: rgb(94, 184, 231);
	}
}
.icon-cultured .p46 {
	&:before {
		content: @icon-cultured-p46;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p47 {
	&:before {
		content: @icon-cultured-p47;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p48 {
	&:before {
		content: @icon-cultured-p48;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-cultured .p49 {
	&:before {
		content: @icon-cultured-p49;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-cultured .p50 {
	&:before {
		content: @icon-cultured-p50;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-cultured .p51 {
	&:before {
		content: @icon-cultured-p51;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-cultured .p52 {
	&:before {
		content: @icon-cultured-p52;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-cultured .p53 {
	&:before {
		content: @icon-cultured-p53;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-cultured .p54 {
	&:before {
		content: @icon-cultured-p54;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-cultured .p55 {
	&:before {
		content: @icon-cultured-p55;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-cultured .p56 {
	&:before {
		content: @icon-cultured-p56;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-cultured .p57 {
	&:before {
		content: @icon-cultured-p57;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-cultured .p58 {
	&:before {
		content: @icon-cultured-p58;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-cultured .p59 {
	&:before {
		content: @icon-cultured-p59;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-cultured .p60 {
	&:before {
		content: @icon-cultured-p60;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-cultured .p61 {
	&:before {
		content: @icon-cultured-p61;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-cultured .p62 {
	&:before {
		content: @icon-cultured-p62;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-cultured .p63 {
	&:before {
		content: @icon-cultured-p63;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-cultured .p64 {
	&:before {
		content: @icon-cultured-p64;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-cultured .p65 {
	&:before {
		content: @icon-cultured-p65;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-cultured .p66 {
	&:before {
		content: @icon-cultured-p66;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-cultured .p67 {
	&:before {
		content: @icon-cultured-p67;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-cultured .p68 {
	&:before {
		content: @icon-cultured-p68;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-demand .p1 {
	&:before {
		content: @icon-demand-p1;
		color: rgb(255, 255, 255);
	}
}
.icon-demand .p2 {
	&:before {
		content: @icon-demand-p2;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-demand .p3 {
	&:before {
		content: @icon-demand-p3;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-demand .p4 {
	&:before {
		content: @icon-demand-p4;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-demand .p5 {
	&:before {
		content: @icon-demand-p5;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-demand .p6 {
	&:before {
		content: @icon-demand-p6;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-demand .p7 {
	&:before {
		content: @icon-demand-p7;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-demand .p8 {
	&:before {
		content: @icon-demand-p8;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-demand .p9 {
	&:before {
		content: @icon-demand-p9;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p1 {
	&:before {
		content: @icon-fish-p1;
		color: rgb(231, 237, 250);
	}
}
.icon-fish .p2 {
	&:before {
		content: @icon-fish-p2;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p3 {
	&:before {
		content: @icon-fish-p3;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p4 {
	&:before {
		content: @icon-fish-p4;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p5 {
	&:before {
		content: @icon-fish-p5;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p6 {
	&:before {
		content: @icon-fish-p6;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p7 {
	&:before {
		content: @icon-fish-p7;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p8 {
	&:before {
		content: @icon-fish-p8;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-fish .p9 {
	&:before {
		content: @icon-fish-p9;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p10 {
	&:before {
		content: @icon-fish-p10;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-fish .p11 {
	&:before {
		content: @icon-fish-p11;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p12 {
	&:before {
		content: @icon-fish-p12;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p13 {
	&:before {
		content: @icon-fish-p13;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p14 {
	&:before {
		content: @icon-fish-p14;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p15 {
	&:before {
		content: @icon-fish-p15;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p16 {
	&:before {
		content: @icon-fish-p16;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p17 {
	&:before {
		content: @icon-fish-p17;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p18 {
	&:before {
		content: @icon-fish-p18;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-fish .p19 {
	&:before {
		content: @icon-fish-p19;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-fish .p20 {
	&:before {
		content: @icon-fish-p20;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p21 {
	&:before {
		content: @icon-fish-p21;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-fish .p22 {
	&:before {
		content: @icon-fish-p22;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p23 {
	&:before {
		content: @icon-fish-p23;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-fish .p24 {
	&:before {
		content: @icon-fish-p24;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-fish .p25 {
	&:before {
		content: @icon-fish-p25;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p26 {
	&:before {
		content: @icon-fish-p26;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-fish .p27 {
	&:before {
		content: @icon-fish-p27;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p1 {
	&:before {
		content: @icon-happy-p1;
		color: rgb(231, 237, 250);
	}
}
.icon-happy .p2 {
	&:before {
		content: @icon-happy-p2;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-happy .p3 {
	&:before {
		content: @icon-happy-p3;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-happy .p4 {
	&:before {
		content: @icon-happy-p4;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-happy .p5 {
	&:before {
		content: @icon-happy-p5;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p6 {
	&:before {
		content: @icon-happy-p6;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p7 {
	&:before {
		content: @icon-happy-p7;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-happy .p8 {
	&:before {
		content: @icon-happy-p8;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-happy .p9 {
	&:before {
		content: @icon-happy-p9;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-happy .p10 {
	&:before {
		content: @icon-happy-p10;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-happy .p11 {
	&:before {
		content: @icon-happy-p11;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-happy .p12 {
	&:before {
		content: @icon-happy-p12;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-happy .p13 {
	&:before {
		content: @icon-happy-p13;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-happy .p14 {
	&:before {
		content: @icon-happy-p14;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-happy .p15 {
	&:before {
		content: @icon-happy-p15;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-happy .p16 {
	&:before {
		content: @icon-happy-p16;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-happy .p17 {
	&:before {
		content: @icon-happy-p17;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p18 {
	&:before {
		content: @icon-happy-p18;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p19 {
	&:before {
		content: @icon-happy-p19;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-happy .p20 {
	&:before {
		content: @icon-happy-p20;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-happy .p21 {
	&:before {
		content: @icon-happy-p21;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p22 {
	&:before {
		content: @icon-happy-p22;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p23 {
	&:before {
		content: @icon-happy-p23;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p24 {
	&:before {
		content: @icon-happy-p24;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p25 {
	&:before {
		content: @icon-happy-p25;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p26 {
	&:before {
		content: @icon-happy-p26;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p27 {
	&:before {
		content: @icon-happy-p27;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p28 {
	&:before {
		content: @icon-happy-p28;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p29 {
	&:before {
		content: @icon-happy-p29;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p30 {
	&:before {
		content: @icon-happy-p30;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p31 {
	&:before {
		content: @icon-happy-p31;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-happy .p32 {
	&:before {
		content: @icon-happy-p32;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-integration {
	&:before {
		content: @icon-integration;
	}
}
.icon-job .p1 {
	&:before {
		content: @icon-job-p1;
		color: rgb(231, 237, 250);
	}
}
.icon-job .p2 {
	&:before {
		content: @icon-job-p2;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-job .p3 {
	&:before {
		content: @icon-job-p3;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p4 {
	&:before {
		content: @icon-job-p4;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p5 {
	&:before {
		content: @icon-job-p5;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p6 {
	&:before {
		content: @icon-job-p6;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p7 {
	&:before {
		content: @icon-job-p7;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p8 {
	&:before {
		content: @icon-job-p8;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p9 {
	&:before {
		content: @icon-job-p9;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p10 {
	&:before {
		content: @icon-job-p10;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p11 {
	&:before {
		content: @icon-job-p11;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p12 {
	&:before {
		content: @icon-job-p12;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p13 {
	&:before {
		content: @icon-job-p13;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p14 {
	&:before {
		content: @icon-job-p14;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p15 {
	&:before {
		content: @icon-job-p15;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p16 {
	&:before {
		content: @icon-job-p16;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p17 {
	&:before {
		content: @icon-job-p17;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p18 {
	&:before {
		content: @icon-job-p18;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p19 {
	&:before {
		content: @icon-job-p19;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p20 {
	&:before {
		content: @icon-job-p20;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p21 {
	&:before {
		content: @icon-job-p21;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p22 {
	&:before {
		content: @icon-job-p22;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p23 {
	&:before {
		content: @icon-job-p23;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p24 {
	&:before {
		content: @icon-job-p24;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p25 {
	&:before {
		content: @icon-job-p25;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p26 {
	&:before {
		content: @icon-job-p26;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p27 {
	&:before {
		content: @icon-job-p27;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p28 {
	&:before {
		content: @icon-job-p28;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p29 {
	&:before {
		content: @icon-job-p29;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p30 {
	&:before {
		content: @icon-job-p30;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p31 {
	&:before {
		content: @icon-job-p31;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p32 {
	&:before {
		content: @icon-job-p32;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-job .p33 {
	&:before {
		content: @icon-job-p33;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-job .p34 {
	&:before {
		content: @icon-job-p34;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-job .p35 {
	&:before {
		content: @icon-job-p35;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-job .p36 {
	&:before {
		content: @icon-job-p36;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-job .p37 {
	&:before {
		content: @icon-job-p37;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-job .p38 {
	&:before {
		content: @icon-job-p38;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-job .p39 {
	&:before {
		content: @icon-job-p39;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-job .p40 {
	&:before {
		content: @icon-job-p40;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-job .p41 {
	&:before {
		content: @icon-job-p41;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-job .p42 {
	&:before {
		content: @icon-job-p42;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-job .p43 {
	&:before {
		content: @icon-job-p43;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-job .p44 {
	&:before {
		content: @icon-job-p44;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-job .p45 {
	&:before {
		content: @icon-job-p45;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-job .p46 {
	&:before {
		content: @icon-job-p46;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-job .p47 {
	&:before {
		content: @icon-job-p47;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-job .p48 {
	&:before {
		content: @icon-job-p48;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-lose .p1 {
	&:before {
		content: @icon-lose-p1;
		color: rgb(255, 255, 255);
	}
}
.icon-lose .p2 {
	&:before {
		content: @icon-lose-p2;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-lose .p3 {
	&:before {
		content: @icon-lose-p3;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-lose .p4 {
	&:before {
		content: @icon-lose-p4;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-lose .p5 {
	&:before {
		content: @icon-lose-p5;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-lose .p6 {
	&:before {
		content: @icon-lose-p6;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-lose .p7 {
	&:before {
		content: @icon-lose-p7;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-lose .p8 {
	&:before {
		content: @icon-lose-p8;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p9 {
	&:before {
		content: @icon-lose-p9;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p10 {
	&:before {
		content: @icon-lose-p10;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p11 {
	&:before {
		content: @icon-lose-p11;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-lose .p12 {
	&:before {
		content: @icon-lose-p12;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p13 {
	&:before {
		content: @icon-lose-p13;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p14 {
	&:before {
		content: @icon-lose-p14;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p15 {
	&:before {
		content: @icon-lose-p15;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p16 {
	&:before {
		content: @icon-lose-p16;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p17 {
	&:before {
		content: @icon-lose-p17;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p18 {
	&:before {
		content: @icon-lose-p18;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p19 {
	&:before {
		content: @icon-lose-p19;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p20 {
	&:before {
		content: @icon-lose-p20;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p21 {
	&:before {
		content: @icon-lose-p21;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p22 {
	&:before {
		content: @icon-lose-p22;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p23 {
	&:before {
		content: @icon-lose-p23;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-lose .p24 {
	&:before {
		content: @icon-lose-p24;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-lose .p25 {
	&:before {
		content: @icon-lose-p25;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-management .p1 {
	&:before {
		content: @icon-management-p1;
		color: rgb(255, 255, 255);
	}
}
.icon-management .p2 {
	&:before {
		content: @icon-management-p2;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p3 {
	&:before {
		content: @icon-management-p3;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p4 {
	&:before {
		content: @icon-management-p4;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p5 {
	&:before {
		content: @icon-management-p5;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p6 {
	&:before {
		content: @icon-management-p6;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p7 {
	&:before {
		content: @icon-management-p7;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p8 {
	&:before {
		content: @icon-management-p8;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p9 {
	&:before {
		content: @icon-management-p9;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p10 {
	&:before {
		content: @icon-management-p10;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p11 {
	&:before {
		content: @icon-management-p11;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p12 {
	&:before {
		content: @icon-management-p12;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p13 {
	&:before {
		content: @icon-management-p13;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p14 {
	&:before {
		content: @icon-management-p14;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p15 {
	&:before {
		content: @icon-management-p15;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p16 {
	&:before {
		content: @icon-management-p16;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p17 {
	&:before {
		content: @icon-management-p17;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p18 {
	&:before {
		content: @icon-management-p18;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-management .p19 {
	&:before {
		content: @icon-management-p19;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-quality .p1 {
	&:before {
		content: @icon-quality-p1;
		color: rgb(255, 255, 255);
	}
}
.icon-quality .p2 {
	&:before {
		content: @icon-quality-p2;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-quality .p3 {
	&:before {
		content: @icon-quality-p3;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-quality .p4 {
	&:before {
		content: @icon-quality-p4;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-quality .p5 {
	&:before {
		content: @icon-quality-p5;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-quality .p6 {
	&:before {
		content: @icon-quality-p6;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-quality .p7 {
	&:before {
		content: @icon-quality-p7;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-quality .p8 {
	&:before {
		content: @icon-quality-p8;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-quality .p9 {
	&:before {
		content: @icon-quality-p9;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-quality .p10 {
	&:before {
		content: @icon-quality-p10;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p1 {
	&:before {
		content: @icon-senior-p1;
		color: rgb(255, 255, 255);
	}
}
.icon-senior .p2 {
	&:before {
		content: @icon-senior-p2;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p3 {
	&:before {
		content: @icon-senior-p3;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-senior .p4 {
	&:before {
		content: @icon-senior-p4;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p5 {
	&:before {
		content: @icon-senior-p5;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-senior .p6 {
	&:before {
		content: @icon-senior-p6;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p7 {
	&:before {
		content: @icon-senior-p7;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-senior .p8 {
	&:before {
		content: @icon-senior-p8;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p9 {
	&:before {
		content: @icon-senior-p9;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-senior .p10 {
	&:before {
		content: @icon-senior-p10;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p11 {
	&:before {
		content: @icon-senior-p11;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-senior .p12 {
	&:before {
		content: @icon-senior-p12;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p13 {
	&:before {
		content: @icon-senior-p13;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-senior .p14 {
	&:before {
		content: @icon-senior-p14;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p15 {
	&:before {
		content: @icon-senior-p15;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-senior .p16 {
	&:before {
		content: @icon-senior-p16;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p17 {
	&:before {
		content: @icon-senior-p17;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-senior .p18 {
	&:before {
		content: @icon-senior-p18;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p19 {
	&:before {
		content: @icon-senior-p19;
		margin-left: -1em;
		color: rgb(0, 0, 0);
	}
}
.icon-senior .p20 {
	&:before {
		content: @icon-senior-p20;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p21 {
	&:before {
		content: @icon-senior-p21;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-senior .p22 {
	&:before {
		content: @icon-senior-p22;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p23 {
	&:before {
		content: @icon-senior-p23;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-senior .p24 {
	&:before {
		content: @icon-senior-p24;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p25 {
	&:before {
		content: @icon-senior-p25;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-senior .p26 {
	&:before {
		content: @icon-senior-p26;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p27 {
	&:before {
		content: @icon-senior-p27;
		margin-left: -1em;
		color: rgb(0, 0, 0);
	}
}
.icon-senior .p28 {
	&:before {
		content: @icon-senior-p28;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-senior .p29 {
	&:before {
		content: @icon-senior-p29;
		margin-left: -1em;
		color: rgb(0, 0, 0);
	}
}
.icon-senior .p30 {
	&:before {
		content: @icon-senior-p30;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p1 {
	&:before {
		content: @icon-tide-p1;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p2 {
	&:before {
		content: @icon-tide-p2;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p3 {
	&:before {
		content: @icon-tide-p3;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p4 {
	&:before {
		content: @icon-tide-p4;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p5 {
	&:before {
		content: @icon-tide-p5;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p6 {
	&:before {
		content: @icon-tide-p6;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p7 {
	&:before {
		content: @icon-tide-p7;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p8 {
	&:before {
		content: @icon-tide-p8;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-tide .p9 {
	&:before {
		content: @icon-tide-p9;
		margin-left: -1em;
		color: rgb(231, 237, 250);
	}
}
.icon-tide .p10 {
	&:before {
		content: @icon-tide-p10;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-tide .p11 {
	&:before {
		content: @icon-tide-p11;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-tide .p12 {
	&:before {
		content: @icon-tide-p12;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-tide .p13 {
	&:before {
		content: @icon-tide-p13;
		margin-left: -1em;
		color: rgb(52, 217, 195);
	}
}
.icon-tide .p14 {
	&:before {
		content: @icon-tide-p14;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p15 {
	&:before {
		content: @icon-tide-p15;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p16 {
	&:before {
		content: @icon-tide-p16;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p17 {
	&:before {
		content: @icon-tide-p17;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p18 {
	&:before {
		content: @icon-tide-p18;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p19 {
	&:before {
		content: @icon-tide-p19;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p20 {
	&:before {
		content: @icon-tide-p20;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p21 {
	&:before {
		content: @icon-tide-p21;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p22 {
	&:before {
		content: @icon-tide-p22;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p23 {
	&:before {
		content: @icon-tide-p23;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p24 {
	&:before {
		content: @icon-tide-p24;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p25 {
	&:before {
		content: @icon-tide-p25;
		margin-left: -1em;
		color: rgb(245, 196, 0);
	}
}
.icon-tide .p26 {
	&:before {
		content: @icon-tide-p26;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-tide .p27 {
	&:before {
		content: @icon-tide-p27;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-tide .p28 {
	&:before {
		content: @icon-tide-p28;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-tide .p29 {
	&:before {
		content: @icon-tide-p29;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-tide .p30 {
	&:before {
		content: @icon-tide-p30;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-tide .p31 {
	&:before {
		content: @icon-tide-p31;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-tide .p32 {
	&:before {
		content: @icon-tide-p32;
		margin-left: -1em;
		color: rgb(255, 134, 113);
	}
}
.icon-tide .p33 {
	&:before {
		content: @icon-tide-p33;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p34 {
	&:before {
		content: @icon-tide-p34;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p35 {
	&:before {
		content: @icon-tide-p35;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p36 {
	&:before {
		content: @icon-tide-p36;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p37 {
	&:before {
		content: @icon-tide-p37;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p38 {
	&:before {
		content: @icon-tide-p38;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p39 {
	&:before {
		content: @icon-tide-p39;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-tide .p40 {
	&:before {
		content: @icon-tide-p40;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-work .p1 {
	&:before {
		content: @icon-work-p1;
		color: rgb(255, 255, 255);
	}
}
.icon-work .p2 {
	&:before {
		content: @icon-work-p2;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-work .p3 {
	&:before {
		content: @icon-work-p3;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-work .p4 {
	&:before {
		content: @icon-work-p4;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-work .p5 {
	&:before {
		content: @icon-work-p5;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-work .p6 {
	&:before {
		content: @icon-work-p6;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-work .p7 {
	&:before {
		content: @icon-work-p7;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-work .p8 {
	&:before {
		content: @icon-work-p8;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-work .p9 {
	&:before {
		content: @icon-work-p9;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-work .p10 {
	&:before {
		content: @icon-work-p10;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-work .p11 {
	&:before {
		content: @icon-work-p11;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-work .p12 {
	&:before {
		content: @icon-work-p12;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-work .p13 {
	&:before {
		content: @icon-work-p13;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-work .p14 {
	&:before {
		content: @icon-work-p14;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.icon-work .p15 {
	&:before {
		content: @icon-work-p15;
		margin-left: -1em;
		color: rgb(0, 161, 223);
	}
}
.icon-close {
	&:before {
		content: @icon-close;
	}
}
.icon-checkmark {
	&:before {
		content: @icon-checkmark;
	}
}
.icon-help {
	&:before {
		content: @icon-help;
	}
}
.icon-arrow-left {
	&:before {
		content: @icon-arrow-left;
	}
}
.icon-quotes-right {
	&:before {
		content: @icon-quotes-right;
	}
}
.icon-quotes-left {
	&:before {
		content: @icon-quotes-left;
	}
}
.icon-arrow-down {
	&:before {
		content: @icon-arrow-down;
	}
}
.icon-hourglass-2 {
	&:before {
		content: @icon-hourglass-2;
	}
}
.icon-hourglass-half {
	&:before {
		content: @icon-hourglass-half;
	}
}
.icon-check_box_outline_blank {
	&:before {
		content: @icon-check_box_outline_blank;
	}
}
.icon-check_box {
	&:before {
		content: @icon-check_box;
	}
}
.icon-database {
	&:before {
		content: @icon-database;
	}
}
.icon-db {
	&:before {
		content: @icon-db;
	}
}
.icon-server {
	&:before {
		content: @icon-server;
	}
}
.icon-host {
	&:before {
		content: @icon-host;
	}
}
.icon-storage {
	&:before {
		content: @icon-storage;
	}
}
.icon-save {
	&:before {
		content: @icon-save;
	}
}
.icon-datecenter {
	&:before {
		content: @icon-datecenter;
	}
}
.icon-display {
	&:before {
		content: @icon-display;
	}
}
.icon-screen {
	&:before {
		content: @icon-screen;
	}
}
.icon-monitor {
	&:before {
		content: @icon-monitor;
	}
}
.icon-computer {
	&:before {
		content: @icon-computer;
	}
}
.icon-desktop {
	&:before {
		content: @icon-desktop;
	}
}
.icon-pc {
	&:before {
		content: @icon-pc;
	}
}
.icon-logo-upstack {
	&:before {
		content: @icon-logo-upstack;
	}
}
.icon-apartment {
	&:before {
		content: @icon-apartment;
	}
}
.icon-bicycle {
	&:before {
		content: @icon-bicycle;
	}
}
.icon-briefcase {
	&:before {
		content: @icon-briefcase;
	}
}
.icon-bug {
	&:before {
		content: @icon-bug;
	}
}
.icon-bullhorn {
	&:before {
		content: @icon-bullhorn;
	}
}
.icon-car {
	&:before {
		content: @icon-car;
	}
}
.icon-cart {
	&:before {
		content: @icon-cart;
	}
}
.icon-cloud {
	&:before {
		content: @icon-cloud;
	}
}
.icon-coffee-cup {
	&:before {
		content: @icon-coffee-cup;
	}
}
.icon-construction {
	&:before {
		content: @icon-construction;
	}
}
.icon-diamond {
	&:before {
		content: @icon-diamond;
	}
}
.icon-keyboard {
	&:before {
		content: @icon-keyboard;
	}
}
.icon-laptop {
	&:before {
		content: @icon-laptop;
	}
}
.icon-pencil {
	&:before {
		content: @icon-pencil;
	}
}
.icon-rocket {
	&:before {
		content: @icon-rocket;
	}
}
.icon-smartphone {
	&:before {
		content: @icon-smartphone;
	}
}
.icon-store {
	&:before {
		content: @icon-store;
	}
}
.icon-thumbs-up {
	&:before {
		content: @icon-thumbs-up;
	}
}
.icon-orchestra {
	&:before {
		content: @icon-orchestra;
	}
}
.icon-mealpal {
	&:before {
		content: @icon-mealpal;
	}
}
.icon-winc {
	&:before {
		content: @icon-winc;
	}
}
.icon-thechive {
	&:before {
		content: @icon-thechive;
	}
}
.icon-invision {
	&:before {
		content: @icon-invision;
	}
}
.icon-truelocal {
	&:before {
		content: @icon-truelocal;
	}
}
.icon-truelocal0 {
	&:before {
		content: @icon-truelocal0;
	}
}
.icon-truelocal {
	&:before {
		content: @icon-truelocal;
	}
}
.icon-info-large {
	&:before {
		content: @icon-info-large;
	}
}

.icon-top1-talent i:first-child {
	&:before {
		content: @icon-top1-talent-p1;
		color: rgb(52, 217, 195);
	}
}
.icon-top1-talent i:nth-child(2) {
	&:before {
		content: @icon-top1-talent-p2;
		margin-left: -1.25em;
		color: rgb(255, 134, 113);
	}
}
.icon-top1-talent i:nth-child(3) {
	&:before {
		content: @icon-top1-talent-p3;
		margin-left: -1.25em;
		color: rgb(64, 180, 229);
	}
}
.icon-top1-talent i:nth-child(4) {
	&:before {
		content: @icon-top1-talent-p4;
		margin-left: -1.25em;
		color: rgb(245, 196, 0);
	}
}
.icon-intelligent-matching i:first-child {
	&:before {
		content: @icon-intelligent-matching-p1;
		color: rgb(255, 134, 113);
	}
}
.icon-intelligent-matching i:nth-child(2) {
	&:before {
		content: @icon-intelligent-matching-p2;
		margin-left: -1.6064453125em;
		color: rgb(245, 196, 0);
	}
}
.icon-intelligent-matching i:nth-child(3) {
	&:before {
		content: @icon-intelligent-matching-p3;
		margin-left: -1.6064453125em;
		color: rgb(52, 217, 195);
	}
}
.icon-intelligent-matching i:nth-child(4) {
	&:before {
		content: @icon-intelligent-matching-p4;
		margin-left: -1.6064453125em;
		color: rgb(64, 180, 229);
	}
}
.icon-intelligent-matching i:nth-child(5) {
	&:before {
		content: @icon-intelligent-matching-p5;
		margin-left: -1.6064453125em;
		color: rgb(64, 180, 229);
	}
}
.icon-flexible-contracts i:first-child {
	&:before {
		content: @icon-flexible-contracts-p1;
		color: rgb(64, 180, 229);
	}
}
.icon-flexible-contracts i:nth-child(2) {
	&:before {
		content: @icon-flexible-contracts-p2;
		margin-left: -1.8232421875em;
		color: rgb(245, 196, 0);
	}
}
.icon-flexible-contracts i:nth-child(3) {
	&:before {
		content: @icon-flexible-contracts-p3;
		margin-left: -1.8232421875em;
		color: rgb(52, 217, 195);
	}
}
.icon-flexible-contracts i:nth-child(4) {
	&:before {
		content: @icon-flexible-contracts-p4;
		margin-left: -1.8232421875em;
		color: rgb(255, 134, 113);
	}
}
.icon-flexible-contracts i:nth-child(5) {
	&:before {
		content: @icon-flexible-contracts-p5;
		margin-left: -1.8232421875em;
		color: rgb(64, 180, 229);
	}
}
.icon-the-right-technology i:first-child {
	&:before {
		content: @icon-the-right-technology-p1;
		color: rgb(64, 180, 229);
	}
}
.icon-the-right-technology i:nth-child(2) {
	&:before {
		content: @icon-the-right-technology-p2;
		margin-left: -1.45703125em;
		color: rgb(245, 196, 0);
	}
}
.icon-the-right-technology i:nth-child(3) {
	&:before {
		content: @icon-the-right-technology-p3;
		margin-left: -1.45703125em;
		color: rgb(52, 217, 195);
	}
}
.icon-the-right-technology i:nth-child(4) {
	&:before {
		content: @icon-the-right-technology-p4;
		margin-left: -1.45703125em;
		color: rgb(64, 180, 229);
	}
}
.icon-the-right-technology i:nth-child(5) {
	&:before {
		content: @icon-the-right-technology-p5;
		margin-left: -1.45703125em;
		color: rgb(255, 134, 113);
	}
}
.icon-we-join-your-team i:first-child {
	&:before {
		content: @icon-we-join-your-team-p1;
		color: rgb(245, 196, 0);
	}
}
.icon-we-join-your-team i:nth-child(2) {
	&:before {
		content: @icon-we-join-your-team-p2;
		margin-left: -1.5712890625em;
		color: rgb(245, 196, 0);
	}
}
.icon-we-join-your-team i:nth-child(3) {
	&:before {
		content: @icon-we-join-your-team-p3;
		margin-left: -1.5712890625em;
		color: rgb(255, 134, 113);
	}
}
.icon-we-join-your-team i:nth-child(4) {
	&:before {
		content: @icon-we-join-your-team-p4;
		margin-left: -1.5712890625em;
		color: rgb(255, 134, 113);
	}
}
.icon-we-join-your-team i:nth-child(5) {
	&:before {
		content: @icon-we-join-your-team-p5;
		margin-left: -1.5712890625em;
		color: rgb(64, 180, 229);
	}
}
.icon-we-join-your-team i:nth-child(6) {
	&:before {
		content: @icon-we-join-your-team-p6;
		margin-left: -1.5712890625em;
		color: rgb(52, 217, 195);
	}
}
.icon-we-join-your-team i:nth-child(7) {
	&:before {
		content: @icon-we-join-your-team-p7;
		margin-left: -1.5712890625em;
		color: rgb(52, 217, 195);
	}
}
.icon-we-join-your-team i:nth-child(8) {
	&:before {
		content: @icon-we-join-your-team-p8;
		margin-left: -1.5712890625em;
		color: rgb(64, 180, 229);
	}
}

.icon-caret {
	&:before {
		content: @icon-caret;
		color: #40b4e5;
	}
}

.icon-heart {
	&:before {
		content: @icon-heart;
	}
}
.icon-clock {
	&:before {
		content: @icon-clock;
	}
}
.icon-location-pin {
	&:before {
		content: @icon-location-pin;
	}
}

.icon-price-tag {
	&:before {
		content: @icon-price-tag;
	}
}
.icon-plus {
	&:before {
		content: @icon-plus;
	}
}
.icon-intercom {
	&:before {
		content: @icon-intercom;
	}
}
.icon-unfold {
	&:before {
		content: @icon-unfold;
	}
}
.icon-juul {
	&:before {
		content: @icon-Juul;
	}
}