.form-group {
    position:relative;
	.form-control {
		height:50px;
		border:1px solid #ddd;
		.placeholder(#aaa);
		.box-shadow(none);
		.border-radius(0);
		.text-format(@circular, 16px, 24px, @black, 400);
	}
	input[type="checkbox"] {
        width:30px;
        height:20px;
        opacity:0;
        ~ i[class*=icon-] {
            pointer-events:none;
            font-size:24px;
            color:#ccc;
            .transform-origin(center center);
            .transition(all .3s ease);
            .position(absolute, 0, auto, auto, 0);
            &.icon-check_box {
                .transform(scale3d(0, 0, 0));
            }
        }
        &:checked {
            ~ i[class*=icon-] {
                .transform(scale3d(0, 0, 0));
                &.icon-check_box {
                    color:@blue;
                    .transform(scale3d(1, 1, 1));
                }
            }
        }
	}
}