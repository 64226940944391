.transition(@value) {
  -webkit-transition: @value;
  -moz-transition: @value;
  -ms-transition: @value;
  -o-transition: @value;
  transition: @value;
}
.transition-transform(@value) {
  -webkit-transition: -webkit-transform @value;
  -moz-transition: -moz-transform @value;
  -o-transition: -o-transform @value;
  transition: transform @value;
}
.animation(@value) {
  -webkit-animation: @value;
  -moz-animation: @value;
  -o-animation: @value;
  animation: @value;
}
.animation-name(@value) {
  -webkit-animation-name: @value;
  -moz-animation-name: @value;
  animation-name: @value;
}
.animation-duration(@value) {
  -webkit-animation-duration: @value;
  -moz-animation-duration: @value;
  animation-duration: @value;
}
.animation-delay(@value) {
  -webkit-animation-delay: @value;
  -moz-animation-delay: @value;
  animation-delay: @value;
}
.content-box {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.border-radius(@value) {
  -webkit-border-radius: @value;
  -moz-border-radius: @value;
  border-radius: @value;
}
.box-sizing(@value) {
  -webkit-box-sizing: @value;
  -moz-box-sizing: @value;
  box-sizing: @value;
}
.box-shadow(@value) {
  -webkit-box-shadow: @value;
  -moz-box-shadow: @value;
  box-shadow: @value;
}
.box-shadow(@value1, @value2) {
  -webkit-box-shadow: @value1, @value2;
  -moz-box-shadow: @value1, @value2;
  box-shadow: @value1, @value2;
}
.backface-visibility(@value) {
  -webkit-backface-visibility: @value;
  -moz-backface-visibility: @value;
  backface-visibility: @value;
}
.translate3d(@x; @y; @z) {
  -webkit-transform: translate3d(@x, @y, @z);
  transform: translate3d(@x, @y, @z);
}
.perspective(@value) {
  -webkit-perspective: @value;
  -moz-perspective: @value;
  perspective: @value;
}

.transform(@value) {
  -webkit-transform: @value;
  -moz-transform: @value;
  -ms-transform: @value;
  -o-transform: @value;
  transform: @value;
}

// Flexbox display

// flex or inline-flex
.display-flex(@display: flex) {
  display: ~"-webkit-@{display}";
  display: ~"-moz-@{display}";
  display: ~"-ms-@{display}box";
  display: ~"-ms-@{display}";
  display: @display;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
.flex(@columns: initial) {
  -webkit-flex: @columns;
  -moz-flex: @columns;
  -ms-flex: @columns;
  flex: @columns;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
.flex-direction(@direction: row) {
  -webkit-flex-direction: @direction;
  -moz-flex-direction: @direction;
  -ms-flex-direction: @direction;
  flex-direction: @direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
.flex-wrap(@wrap: nowrap) {
  -webkit-flex-wrap: @wrap;
  -moz-flex-wrap: @wrap;
  -ms-flex-wrap: @wrap;
  flex-wrap: @wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
.flex-flow(@flow) {
  -webkit-flex-flow: @flow;
  -moz-flex-flow: @flow;
  -ms-flex-flow: @flow;
  flex-flow: @flow;
}

// Display Order
// - applies to: flex items
// <integer>
.flex-order(@order: 0) {
  -webkit-order: @order;
  -moz-order: @order;
  -ms-order: @order;
  order: @order;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
.justify-content(@justify: flex-start) {
  -webkit-justify-content: @justify;
  -moz-justify-content: @justify;
  -ms-justify-content: @justify;
  justify-content: @justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
.align-content(@align: stretch) {
  -webkit-align-content: @align;
  -moz-align-content: @align;
  -ms-align-content: @align;
  align-content: @align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
.align-items(@align: stretch) {
  -webkit-align-items: @align;
  -moz-align-items: @align;
  -ms-align-items: @align;
  align-items: @align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
.align-self(@align: auto) {
  -webkit-align-self: @align;
  -moz-align-self: @align;
  -ms-align-self: @align;
  align-self: @align;
}

.filter(@amount) {
  -webkit-filter: blur(@amount);
  -moz-filter: blur(@amount);
  -ms-filter: blur(@amount);
  -o-filter: blur(@amount);
  filter: blur(@amount);
}

.ellipsis {
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

.text-format(@family, @size, @height, @color:inherit, @weight:normal, @style:normal, @transform:none, @spacing:0){
  font:@style @weight @size/@height @family;
  color:@color;
  text-transform:@transform;
  letter-spacing:@spacing;
}

.position(@type, @top: auto, @right: auto, @bottom: auto, @left: auto, @index: initial) {
  position:@type;
  top:@top;
  right:@right;
  bottom:@bottom;
  left:@left;
  z-index:@index;
}

.flex-center {
  .display-flex;
  .align-items(center);
  .justify-content(center);
}

.blur(@amount) {
  filter: blur(@amount);
  -webkit-filter: blur(@amount);
  -moz-filter: blur(@amount);
  -o-filter: blur(@amount);
  -ms-filter: blur(@amount);
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='#{@amount}');
}

.clear {
  &:before,
  &:after {
    content:'';
    display:block;
    clear:both;
  }
}

.flex-grow(@grow: 0) {
	-webkit-flex-grow: @grow;
	-moz-flex-grow: @grow;
	-ms-flex-grow: @grow;
	flex-grow: @grow;
}

.flex-basis(@basis: 0) {
	-webkit-flex-basis: @basis;
	-moz-flex-basis: @basis;
	-ms-flex-basis: @basis;
	flex-basis: @basis;
}

.placeholder(@color) {
	::-webkit-input-placeholder { color: @color; }
	:-moz-placeholder { color: @color; }
	::-moz-placeholder { color: @color; }
	:-ms-input-placeholder { color: @color; }
}

.flex-center() {
	.display-flex;
	.align-items(center);
	.justify-content(center);
}

.keyframes(@name, @arguments) {
	@-webkit-keyframes @name { @arguments(); }
	@-moz-keyframes @name { @arguments(); }
	@-o-keyframes @name { @arguments(); }
	@keyframes @name { @arguments(); }
}

.opacity(@opacity) {
	opacity: @opacity;
	// IE8 filter
	@opacity-ie: (@opacity * 100);
	filter: ~"alpha(opacity=@{opacity-ie})";
}

.button-variant(@color, @background, @border) {
	color: @color;
	background-color: @background;
	border-color: @border;
	
	&:hover,
	&:focus,
	&.focus,
	&:active,
	&.active,
	.open > .dropdown-toggle& {
		color: @color;
		background-color: darken(@background, 10%);
		border-color: darken(@border, 12%);
	}
	&:active,
	&.active,
	.open > .dropdown-toggle& {
		background-image: none;
	}
	&.disabled,
	&[disabled] {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			background-color: @background;
			border-color: @border;
		}
	}
}

.user-select(@select) {
	-webkit-user-select: @select;
	-moz-user-select: @select;
	-ms-user-select: @select; // IE10+
	user-select: @select;
}

.tab-focus() {
	// Default
	outline: thin dotted;
	// WebKit
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

.transform-origin(@origin) {
	-webkit-transform-origin: @origin;
	-moz-transform-origin: @origin;
	-ms-transform-origin: @origin; // IE9 only
	transform-origin: @origin;
}

.grayscale() {
    filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%);
}