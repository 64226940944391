.rightTalent {
    max-width:960px;
    article {
        margin:0 0 50px;
        .flex-center;
        .img, p {
            position:relative;
            display:block;
            margin:0;
            padding:0 30px;
            .flex(1 0 auto);
        }
        .img {
            max-width:413px;
            height:200px;
            position:relative;
            padding:0 30px;
            .flex-center;
            img {
                display:block;
                width:auto;
                height:150px;
                margin:0 auto;
            }
            .animate {
                width:250px;
                height:160px;
                position:relative;
                img {
                    opacity:0;
                    position:absolute;
                    height:auto;
                    &:nth-child(1) {
                        width:80px;
                        top:0;
                        left:50px;
                        z-index:2;
                    }
                    &:nth-child(2) {
                        width:80px;
                        top:10px;
                        left:110px;
                        z-index:2;
                    }
                    &:nth-child(3) {
                        width:100px;
                        bottom:0;
                        left:0;
                        z-index:1;
                    }
                    &:nth-child(4) {
                        width:100px;
                        bottom:0;
                        left:150px;
                        z-index:1;
                    }
                    &:nth-child(5) {
                        width:auto;
                        height:4px;
                        bottom:54px;
                        left:100px;
                    }
                    &.slideInLeft { opacity:1; }
                }
            }
        }
        p {
            max-width:480px;
            .text-format(@circular, 24px, 36px, @black, 400);
        }
        &:nth-child(even) {
            .flex-flow(row-reverse nowrap);
        }
    }
    &.beta {
        max-width:none;
        margin:0 auto 80px;
        .display-flex;
        .align-items(center);
        .justify-content(space-between);
        article {
            display:block;
            margin:0;
            text-align:center;
            max-width:30%;
            .flex-basis(0);
            .flex-grow(1);
            .img {
                height:150px;
                .transform-origin(center);
                .transform(scale3d(.6, .6, .6));
            }
            p {
                font-size:22px;
                line-height:32px;
                color:darken(@blue, 30%);
                letter-spacing:-.5px;
            }
        }
    }
}

@media (max-width:1199px) {
    .rightTalent.beta {
        article {
            p, .img { max-width:0; }

        }
    }
}
@media (max-width:767px) {
    .rightTalent.beta {
        .flex-flow(row wrap);
        article {
            max-width:none;
            margin:0 auto 30px;
            .flex(1 0 100%);
            p {
                margin:0 auto;
                max-width:300px;
            }
        }
    }
}

.integrate {
    .cogs {
        height:300px;
        .flex-center;
        .icon-cog {
            font-size:97px;
            &.blue { color:@blue; }
            &.red {
                color:@red;
                margin:-200px 6px 0 -5px;
            }
            &.green {
                color:@green;
                margin-top:-30px;
            }
            &.yellow {
                color:@yellow;
                margin:0 0 -95px -55px;
            }
        }
        .icon-cog-empty {
            font-size:134px;
            
            .path2 {
                &:before {
                    color:@blue;
                }
            }
            &:nth-of-type(2) {
                margin-top:-115px;
                margin-left:-14px;
            }
            &:nth-of-type(5) {
                margin:0 0 -34px -94px;
            }
        }
        &.active {
            .icon-cog { .animation(rcw 8s linear forwards); }
            .icon-cog-empty { .animation(rccw 8s linear forwards); }
        }
    }
}

.keyframes(rcw, {
    from { .transform(rotate3d(0,0,1,0)); }
    to { .transform(rotate3d(0,0,1,360deg)); }
});

.keyframes(rccw, {
    from { .transform(rotate3d(0,0,1,0)); }
    to { .transform(rotate3d(0,0,1,-360deg)); }
});

.rightMatch {
    img {
        display:block;
        margin:0 auto 70px;
        max-height:460px;
        width:auto;
    }
}

.dreamTeam {
    img {
        display:block;
        margin:0 auto 70px;
        max-height:300px;
        width:auto;
    }
}


@media (max-width: @screen-md-max) {
	.rightTalent {
		article {
			.img {
				height:200px;
				max-width:50%;
				img {
					width:90%;
					height:auto;
				}
			}
			p {
				max-width:50%;
				font-size:24px;
				line-height:32px;
			}
		}
	}
	.integrate {
		.cogs {
			.transform-origin(center center);
			.transform(scale3d(.7, .7, .7));
		}
	}
}

@media (max-width: @screen-sm-max) {
	.rightTalent {
		article {
			display:block;
			p {
				font-size:22px;
				padding:0 15px;
			}
			.img {
				margin:0 auto;
				max-width:300px;
				img {
					margin:0 auto;
					display:block;
					width:auto;
					height:160px;
				}
				&.animate {
					img {
						height:auto;
						&:nth-child(1) {
							width: 100px;
							top: 16px;
							left: 66px;
						}
						&:nth-child(2) {
							width: 100px;
							top: 23px;
							left: 152px;
						}
						&:nth-child(3) {
							width: 100px;
							top: 82px;
							left: 10px;
						}
						&:nth-child(4) {
							width: 100px;
							top: 82px;
							left: 200px;
						}
						&:nth-child(5) {
							height: 4px;
							top: 128px;
							left: 117px;
						}
					}
				}
			}
			p {
				max-width:none;
				text-align:center;
			}
		}
	}
	.integrate {
		.cogs {
			height:auto;
			.transform-origin(center center);
			.transform(scale3d(.3, .3, .3));
		}
	}
	.rightMatch,
	.dreamTeam {
		img {
			display:block;
			width:auto;
			height:260px;
			margin:0 auto 50px;
		}
	}
}
