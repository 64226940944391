.brand.header2.header-beta {
	&.webinar {
		min-height: 400px;
		padding-bottom: 0;
		h1 {
			max-width: 1000px;
			padding: 0 15px;
			margin: 0 auto;
		}
		p {
			padding: 30px 0 0;
			font-size: 36px;
			line-height: 40px;
			margin-bottom: 0;
		}
	}
}

.webinar-event {
	padding: 70px 0;
	.container {
		display: flex;
		> div {
			flex-basis: 0;
			flex-grow: 1;
			padding: 0 50px;
		}
		.description {
			padding: 0 100px 0 50px;
			em {
				font-style: normal;
				text-decoration: underline;
			}
			ul {
				font-size: 18px;
				line-height: 28px;
				color: #777;
				font-weight: 400;
				padding-right: 50px;
				li {
					margin: 0 0 20px;
				}
			}
		}
		.panel-header {
			display: flex;
			align-items: center;
			background-color: @blue;
			justify-content: center;
			color: @white;
			height: 50px;
			font: 600 20px/30px @circular;
		}
		.panel-body {
			border: 1px solid #eee;
			border-top: 0;
			padding: 20px 30px;
		}
		form {
			min-height: 340px;
			.success {
				display: none;
				&.active {
					display: flex;
				}
				button {
					background-color: @green;
				}
			}
			> div {
				display: flex;
				flex-flow: row wrap;
			}
			input {
				height: 50px;
				text-align: center;
				padding: 0 15px;
				border: 1px solid #eee;
				margin: 0 0 20px;
				flex: 1 0 100%;
				max-width: 100%;
				&:focus {
					outline: none;
				}
			}
			button {
				display: block;
				width: 200px;
				margin: 0 auto;
				height: 50px;
				background-color: @blue;
				color: @white;
				border: 0;
			}
			em {
				flex: 1 0 100%;
				color: @red;
				display: block;
				text-align: center;
				padding: 20px 0;
			}
		}
		small {
			display: block;
			text-align: center;
			a {
				padding: 0 10px;
				text-decoration: underline;
			}
		}
	}
}

.about-yossi {
	background-color: #f0f0f0;
	padding: 70px 0;
	.container {
		display: flex;
		align-items: center;
		img {
			display: block;
			width: 200px;
			height: 200px;
			border-radius: 50%;
		}
		h3 {
			margin: 0;
			font: normal 500 28px/40px @circular;
			color: #003448;
			text-transform: none;
			letter-spacing: 0;
		}
		b {
			margin: 0;
			font: normal 400 18px/32px @circular;
			color: #999;
			text-transform: uppercase;
			letter-spacing: 0;
		}
		p {
			margin: 15px 0 0;
			font: normal 400 20px/30px @circular;
			color: #000;
			text-transform: none;
			letter-spacing: 0;
		}
	}
}

.find-developer {
	text-align: center;
	padding: 70px 0;
	h3 {
		font-size: 24px;
		color: @blue;
		margin: 0 0 30px;
	}
	a {
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

@media (max-width: 991px) {
	.brand.header2.header-beta {
		&.webinar {
			> div {
				max-width: 700px;
				margin: 0 auto;
			}
			h1 {
				font-size: 40px;
				line-height: 50px;
			}
			p {
				font-size: 26px;
				line-height: 30px;
			}
		}
	}
	.webinar-event {
		.container {
			> div {
				padding: 0 15px;
			}
			.description {
				padding: 0 15px;
			}
		}
	}
}

@media (max-width: 767px) {
	.brand.header2.header-beta {
		&.webinar {
			padding: 154px 0 0;
			> div {
				max-width: 320px;
				margin: 0 auto;
			}
			h1 {
				font-size: 32px;
				line-height: 40px;
				padding: 0;
			}
			p {
				font-size: 20px;
				line-height: 26px;
			}
		}
	}
	.webinar-event {
		.container {
			display: block;
		}
	}
	.about-yossi {
		.container {
			display: block;
			img {
				width: 160px;
				height: 160px;
				margin: 0 auto;
			}
			section {
				text-align: center;
			}
		}
	}
}
