header {
	&.apply-header {
		padding: 0 415px 0 0;
		background-color: #fff;
		border-bottom: 1px solid #f0f0f0;
		
		.container {
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			
			a {
				display: block;
				color: @blue;
				font-size: 42px;
				i {
					display: block;
				}
			}
		}
	}
}

.apply-page {
	padding: 60px 0 0;
}

.apply-content {
	padding: 70px 415px 163px 0;
	min-height: 100vh;
	margin: -60px auto -143px;
	display: flex;
	flex-direction: column;
	button {
		&:focus {
			outline: none;
		}
	}
	.tabs {
		list-style: none;
		padding: 0;
		margin: 0 auto 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row-reverse;
		max-width: 400px;
		li {
			padding: 0 50px 0 0;
			position: relative;
			i {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 50px;
				height: 50px;
				border: 1px solid #e0e0e0;
				color: #ccc;
				font-size: 28px;
				cursor: pointer;
				border-radius: 50%;
				&.icon-check {
					position: absolute;
					left: 0;
					top: 0;
					color: @green2;
					background-color: #fff;
					opacity: 0;
					pointer-events: none;
					display: flex;
					align-items: center;
					justify-content: center;
					border: 1px solid @green2;
					transition: all .4s ease;
				}
			}
			span {
				text-transform: uppercase;
				font-size: 11px;
				font-weight: 600;
				color: @blue;
				display: block;
				position: absolute;
				top: 100%;
				left: 50%;
				margin: 5px 0 0 -25px;
				transform: translate(-50%, 0);
				white-space: nowrap;
				transition: all .4s ease;
				opacity: 0;
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				right: 0;
				top: 50%;
				left: 50px;
				transform: translate(0, -50%);
				border-bottom: 1px solid #e0e0e0;
			}
			&:before {
				content: '';
				display: block;
				transition: all .4s ease;
				position: absolute;
				border-bottom: 1px solid @green2;
				top: 50%;
				left: 50px;
				width: 0;
			}
			&:first-child {
				padding: 0;
				
				&:after,
				&:before {
					display: none;
				}
				span {
					margin: 5px 0 0;
				}
			}
			&.active {
				i {
					animation: activeIcon .4s ease .4s forwards;
				}
				span {
					opacity: 1;
				}
				~ li {
					i {
						color: #fff;
						border-color: #fff;
						
						&.icon-check {
							opacity: 1;
							color: @green2;
							border-color: @green2;
						}
					}
					span {
						opacity: 1;
						color: @green2;
					}
					&:before {
						width: 50px;
					}
					&:hover {
						i {
							color: @green2;
							border-color: @green2;
							&.icon-check {
								opacity: 0;
							}
						}
					}
				}
			}
		}
		
	}
	
	.tab-container {
		margin: 0 auto;
		width: 100%;
		max-width: 550px;
		position: relative;
		flex: 1;
		overflow: hidden;
		display: flex;
		
		&.tab12 {
			.question {
				transform: translate3d(-100%, 0, 0);
			}
		}
		&.tab13 {
			.question {
				transform: translate3d(-200%, 0, 0);
			}
		}
		&.tab14 {
			.question {
				transform: translate3d(-300%, 0, 0);
			}
		}
		&.tab21 {
			.question {
				transform: translate3d(-400%, 0, 0);
			}
		}
		&.tab22 {
			.question {
				transform: translate3d(-500%, 0, 0);
			}
		}
		&.tab31 {
			.question {
				transform: translate3d(-600%, 0, 0);
			}
		}
		&.tab41 {
			.question {
				transform: translate3d(-700%, 0, 0);
			}
		}
		
		h2 {
			margin: 0 0 20px;
			text-align: center;
			font-size: 28px;
			letter-spacing: -1px;
			color: @blue;
			font-weight: 400;
		}
		h5 {
			color: #999;
			font-size: 22px;
			margin: 0 0 20px;
			letter-spacing: -1px;
			font-weight: 400;
			text-align: center;
		}
		.actions {
			display: flex;
			align-items: center;
			justify-content: center;
			button {
				margin: 0 25px;
				height: 50px;
				background-color: #fff;
				border: 1px solid #e0e0e0;
				color: #aaa;
				text-transform: uppercase;
				padding: 0;
				width: 170px;
				font-size: 14px;
				font-weight: 600;
				transition: all .4s ease;
				&.active {
					background-color: @blue;
					border-color: @blue;
					color: #fff;
				}
				&:hover {
					border-color: fadeout(@blue, 50%);
					color: fadeout(@blue, 50%);
				}
			}
		}
		.tech {
			width: 470px;
			margin: 0 auto;
			padding: 0;
			list-style: none;
			display: flex;
			justify-content: space-between;
			flex-flow: row wrap;
			li {
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 1 0 auto;
				max-width: 70px;
				position: relative;
				overflow: hidden;
				margin: 0 0 10px;
				color: #aaa;
				input {
					display: block;
					width: 100%;
					height: 100%;
					margin: 0;
					opacity: 0;
					cursor: pointer;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 1;
					position: absolute;
					&:checked {
						~ span {
							color: @blue;
							border-color: @blue;
						}
					}
				}
				img {
					width: 70px;
					height: 70px;
					filter: grayscale(1);
					transition: all .3s ease;
					margin: 0 0 3px;
				}
				span {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					flex: 1;
					text-align: center;
					height: 60px;
					border: 1px solid #e0e0e0;
					border-radius: 5px;
					padding: 0 5px;
				}
				&:hover {
					span {
						color: fadeout(@blue, 50%);
						border-color: fadeout(@blue, 50%);
					}
				}
			}
		}
		.multiple-select-wrapper {
			max-width: 470px;
			margin: 0 auto;
			.multiple-select-actions {
				display: flex;
				align-items: center;
				justify-content: space-between;
				span, b {
					height: 30px;
					display: flex;
					align-items: center;
					color: @blue;
					font-size: 12px;
					cursor: pointer;
					font-weight: 400;
				}
			}
		}
		.questions-wrapper {
			display: flex;
		}
		.question {
			opacity: 0;
			transition: all .4s ease;
			pointer-events: none;
			padding: 30px 0 0;
			flex: 1 0 100%;
			max-width: 550px;
			
			.groupActions {
				list-style: none;
				max-width: 470px;
				margin: 0 auto;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				li {
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 50px;
					background-color: #fff;
					border: 1px solid #e0e0e0;
					color: #aaa;
					text-transform: uppercase;
					padding: 0;
					margin: 0 5px;
					flex: 1 0 146px;
					max-width: 146px;
					font-size: 14px;
					font-weight: 600;
					transition: all .4s ease;
					&.active {
						border-color: @blue;
						color: @blue;
					}
					&:hover {
						border-color: fadeout(@blue, 59%);
						color: fadeout(@blue, 50%);
					}
				}
			}
			&.schedule-call {
				max-width: 550px;
				margin: 0 auto;
				h2 {
					padding: 0 15px;
				}
				h3 {
					text-align: center;
					font-size: 16px;
					font-weight: 400;
					color: #999;
					margin: 0 0 10px;
				}
				.btn-schedule {
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 180px;
					margin: 30px auto;
					text-transform: uppercase;
					font-weight: 600;
					color: #fff;
					background-color: @blue;
					border-radius: 3px;
				}
				.account-manager {
					border: 1px solid #e0e0e0;
					display: flex;
					align-items: center;
					padding: 20px;
					img {
						display: block;
						background-color: #f0f0f0;
						min-width: 150px;
						max-width: 150px;
						height: 150px;
						border-radius: 50%;
						border: 1px solid #e0e0e0;
						margin: 0 20px 0 0;
					}
					.details {
						flex: 1;
						ul {
							padding: 20px 0 0;
							margin: 0;
							li {
								display: flex;
								align-items: center;
								margin: 0 0 5px;
								b {
									display: block;
									margin: 0 10px 0 0;
								}
								span {
									display: block;
									color: @blue;
								}
							}
						}
					}
				}
			}
			
			&.active {
				pointer-events: auto;
				opacity: 1;
			}
		}
		
		.form {
			margin: 0 auto;
			max-width: 470px;
			padding: 10px 0;
			.form-group {
				margin: 0 0 20px;
				position: relative;
				
				input {
					display: block;
					box-sizing: border-box;
					width: 100%;
					padding: 0 10px;
					font-size: 12px;
					height: 40px;
					border: 1px solid #e0e0e0;
					transition: all .4s ease;
					&:focus {
						outline: none;
						border-color: @blue;
					}
				}
				> span {
					position: absolute;
					top: 100%;
					left: 0;
					display: block;
					padding: 0;
					font-size: 12px;
					letter-spacing: 0;
					color: @red;
					opacity: 0;
					transition: .4s ease;
				}
				
				&.error {
					input {
						border-color: @red;
					}
					> span {
						opacity: 1;
						pointer-events: none;
					}
				}
				
				.intl-tel-input {
					display: flex;
					
					.flag-container {
						position: static;
						border: 1px solid #e0e0e0;
						border-right: 0;
						
						.country-list {
							font-size: 14px;
						}
					}
					input {
						flex: 1;
					}
				}
			}
			button {
				margin: 0 5px 10px;
				height: 50px;
				background-color: @green2;
				border-radius: 25px;
				color: #fff;
				text-transform: uppercase;
				padding: 0 20px;
				border: 0;
				font-size: 14px;
				font-weight: 600;
				transition: all .4s ease;
			}
		}
	}
	.submit {
		padding: 20px 0 0;
		text-align: center;
		button {
			margin: 0 5px 10px;
			height: 50px;
			background-color: @green2;
			border-radius: 25px;
			color: #fff;
			text-transform: uppercase;
			padding: 0 20px;
			border: 0;
			font-size: 14px;
			font-weight: 600;
			transition: all .4s ease;
			&.back {
				background-color: #e0e0e0;
				border-color: #e0e0e0;
				color: #999;
				&:hover {
					background-color: #ccc;
					color: #777;
				}
			}
			&:hover {
				background-color: darken(@green2, 10%);
			}
			&[disabled] {
				cursor: not-allowed;
				background-color: #ccc;
			}
		}
		small {
			font-size: 11px;
			line-height: 14px;
			color: #ccc;
			a {
				color: @blue;
			}
		}
	}
	.ui-select-bootstrap .ui-select-choices-row>span {
		//padding: 5px 15px;
	}
	.ui-select-multiple.ui-select-bootstrap {
		max-width: 470px;
		margin: 0 auto;
		position: relative;
		box-shadow: none;
		border: 0;
		padding: 0;
		border-radius: 0;
		> div {
			display: flex;
			flex-direction: column-reverse;
			
			> span {
				width: 100%;
				display: flex;
				align-items: center;
				flex-flow: row wrap;
				
				> span {
					height: 40px;
					margin: 0 5px 5px 0;
					background-color: @blue;
					border-radius: 20px;
					position: relative;
					padding: 0 40px 0 15px;
					color: #fff;
					display: flex;
					align-items: center;
					> span.ui-select-match-item {
						position: static;
						color: #fff;
						display: block;
						margin: 0;
						padding: 0;
						border: 0;
						.close {
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							margin: 0;
							width: 40px;
							display: flex;
							align-items: center;
							justify-content: center;
							color: #fff;
							text-indent: -5px;
							padding-bottom: 3px;
						}
					}
				}
			}
			input {
				border: 1px solid #e0e0e0;
				height: 40px;
				border-radius: 25px;
				padding: 0 20px;
				width: 100% !important;
				margin: 0 0 10px;
				box-sizing: border-box;
			}
		}
		ul {
			background-color: #fff;
			position: absolute;
			left: -1px;
			right: -1px;
			width: auto;
			padding: 0;
			top: 100%;
			display: block;
			margin: 3px 0 0;
			border: 1px solid #e0e0e0;
		}
		.ui-select-match-item {
			width: auto;
			height: auto;
			border: 1px solid #e0e0e0;
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row-reverse;
			color: @blue;
			font-size: 12px;
			padding: 2px 5px;
			letter-spacing: 0;
			font-weight: 600;
			text-transform: uppercase;
			border-radius: 0;
		}
		.ui-select-match .close {
			display: block;
			position: relative;
			top: -1px;
			font-size: 16px;
			line-height: 16px;
			overflow: visible;
			margin: 0 0 0 5px;
			color: #aaa;
		}
	}
}

.apply-footer {
	padding: 0;
	background-color: @blue;
	> div {
		padding: 0 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 50px;
		font-size: 13px;
		font-weight: 400;
		color: #fff;
		a {
			color: #fff;
		}
	}
}

.apply-trustedby {
	padding: 0 415px 0 15px;
	h4 {
		font-size: 18px;
		margin: 0 0 10px;
		color: #aaa;
	}
	.companies {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0 10px;
		i {
			color: @blue;
			font-size: 36px;
			line-height: 50px;
			overflow: visible;
			display: block;
			&.icon-juul {
				font-size: 100px;
			}
		}
	}
}

.sidebar-apply {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 50px;
	width: 400px;
	z-index: 10;
	background-color: #f6f6f6;
	border-left: 1px solid #f0f0f0;
	
	.rightTalent.beta {
		flex-flow: column nowrap;
		justify-content: space-around;
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		
		article {
			max-width: none;
			flex: 0;
		}
	}
}

@keyframes activeIcon {
	from { color: #ccc; border-color: #e0e0e0; }
	to { color: @blue; border-color: @blue; }
}

@media (max-width: 1199px) {
	header {
		&.apply-header {
			padding: 0;
		}
	}
	
	.apply-content {
		padding: 70px 0 143px;
		margin: -60px auto -123px;
		display: flex;
	}
	
	.sidebar-apply {
		display: none;
	}
	
	.apply-trustedby {
		padding: 0 15px;
		
		.companies {
			i {
				font-size: 30px;
				line-height: 30px;
				&.icon-juul {
					font-size: 60px;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.apply-content {
		padding: 70px 10px 133px;
		margin: -60px auto -113px;
		
		.tabs {
			li {
				padding: 0 30px 0 0;
				span {
					margin: 5px 0 0 -15px;
				}
				&.active {
					~ li {
						&:before {
							width: 30px;
						}
					}
				}
			}
		}
		.tab-container {
			h2 {
				font-size: 22px;
			}
			.actions {
				justify-content: space-between;
				button {
					margin: 0 5px;
				}
			}
			.question {
				.groupActions {
					display: block;
					
					li {
						margin: 0 auto 10px;
					}
				}
				&.schedule-call {
					.account-manager {
						display: block;
						
						img {
							margin: 0 auto 20px;
						}
					}
				}
			}
		}
	}
	.apply-trustedby {
		padding: 0 5px;
		.companies {
			i {
				font-size: 20px;
				line-height: 20px;
				&.icon-juul {
					font-size: 40px;
				}
			}
		}
	}
	footer.apply-footer {
		> div {
			padding: 15px;
			height: auto;
			display: block;
			
			.copy {
				margin: 0 0 5px;
			}
		}
	}
}

@media (max-width: 549px) {
	.apply-content {
		max-width: 100vw;
		padding: 70px 10px 124px;
		
		.tab-container {
			padding: 20px 0 0;
			
			.question {
				max-width: 100vw;
				padding: 0 10px;
				
				&.schedule-call {
					max-width: 100vw;
					padding: 0 10px;
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.apply-content {
		padding: 70px 0 124px;
		margin: -60px auto -124px;
	}
	.apply-trustedby {
		.companies {
			i {
				font-size: 14px;
				line-height: 14px;
				&.icon-juul {
					font-size: 28px;
				}
			}
		}
	}
}
