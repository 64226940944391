.allAbout {
    .display-flex;
    .flex-flow(row wrap);
    article {
        padding:0 20px;
        margin:0 0 100px 0;
        max-width:50%;
        .flex(1 0 50%);
        .align-items(center);
        .display-flex;
        i[class*="icon-"] {
            display:block;
            font-size:100px;
            margin:0 40px 0 0;
            color:#00a0df;
            &.icon-senior {
                .p19,
                .p27,
                .p29 {
                    &:before {
                        color:@white;
                    }
                }
            }
        }
        p {
            margin:0 0 10px;
            text-align:left;
            .text-format(@circular, 30px, 40px, @black, 400);
        }
        span {
            .text-format(@circular, 22px, 30px, #444, 300);
        }
        > div {
            opacity:0;
            .transform-origin(center center);
            .transition(all .5s ease);
            .translate3d(0, 50px, 0);
        }
        > i {
            opacity:0;
            .transform-origin(center center);
            .transition(all .5s ease);
            .translate3d(-50px, 0, 0);
        }
        &.active {
            > div,
            > i {
                opacity:1;
                .translate3d(0, 0, 0);
            }
        }
    }
}

.bigPond {
    text-align:center;
    .icon-fish {
        margin:0 0 100px;
        font-size:400px;
        max-height:300px;
        .flex-center;
    }
}

.timeMoney {
    text-align:center;
    .icon-lose {
        margin:0 0 100px;
        font-size:400px;
        max-height:300px;
        .flex-center;
    }
}

.jobDone {
    text-align:center;
    .icon-job {
        font-size:400px;
        margin:0 0 100px;
        max-height:300px;
        .flex-center;
    }
}

.explore {
    text-align:center;
    .icon-cultured {
        margin:0 0 100px;
        font-size:400px;
        max-height:300px;
        .flex-center;
    }
}

.happyTeam {
    text-align:center;
    .icon-happy {
        margin:0 0 100px;
        font-size:400px;
        max-height:300px;
        .flex-center;
    }
}

.theBest {
    .table {
        font-size:18px;
        border:1px solid #00a0df;
        border-right:0;
        margin:0 auto 100px;
        max-width:1000px;
        .display-flex;
        em, span, b, i {
            height:70px;
            .display-flex;
            .align-items(center);
        }
        .icon-checkmark {
            font-size:46px;
            color:#34d9c3;
            height:90px;
            .flex-center;
        }
        .icon-close {
            font-size:46px;
            color:#ff8671;
            height:90px;
            .flex-center;
        }
        b {
            height:50px;
            border-bottom:1px solid #00a0df;
        }
        em {
            font-weight:600;
            font-style:normal;
            font-size:22px;
        }
        .col {
            border-right:1px solid #00a0df;
            &:not(.first) {
                .flex-basis(0);
                .flex-grow(1);
                span,
                i, b {
                    text-align:center;
                    .flex-center;
                }
                [class*="icon-"] {
                    .flex-center;
                }
            }
            &:nth-child(2) {
                background-color:#edf5fb;
            }
            &.first {
                max-width:30%;
                .flex(1 0 30%);
                b, em {
                    padding:0 36px;
                }
            }
        }
    }
}

section {
    &.figure {
        .lead {
            opacity:0;
            .transition(all .5s ease);
            .translate3d(0, 100px, 0);
            &[data=active] {
                opacity:1;
                .translate3d(0, 0, 0);
            }
        }
        > i, > .table {
            opacity:0;
            .transition(all .5s ease);
            .transform-origin(center center);
            .transform(scale3d(0, 0, 0));
            &[data=active] {
                opacity:1;
                .transform(scale3d(1, 1, 1));
            }
        }
    }
}

.betaAbout {
    .allAbout {
        article {
            i[class*=icon-] {
                font-size:70px;
                margin:0 40px 0 30px;
                &.yellow { 
                    > i:before {
                        color:@yellow;
                    }
                }
                &.green {
                    > i:before {
                        color:@green;
                    }
                }
                &.orange {
                    > i:before {
                        color:@red;
                    }
                }
                &.icon-senior {
                    > .p1, > .p3, > .p5, > .p7, > .p9, > .p11, > .p13, > .p15,
                    > .p17, > .p21, > .p19, > .p21, > .p23, > .p27, > .p29,
                    > .p25, {
                        &:before { color:@white; }
                    }
                }
                &.icon-work {
                    > .p1, > .p3, > .p8, > .p10, > .p12, > .p14 {
                        &:before { color:@white; }
                    }
                }
                &.icon-boarding {
                    > .p2, > .p4, > .p7, > .p9, > .p11, > .p13 {
                        &:before { color:@white; }
                    }
                }
                &.icon-demand {
                    > .p1, > .p3, > .p8 {
                        &:before { color:@white; }
                    }
                }
                &.icon-management {
                    > .p1 {
                        &:before { color:@white; }
                    }
                }
            }
            p {
                letter-spacing:-1px;
                color:darken(@blue, 30%);
            }
            span {
                font:300 22px/30px @nunito;
            }
        }
    }
    .lead {
        p {
            max-width:none;
            padding:0 50px;
        }
    }
    .devKpi {
        padding: 0 0 100px;
        ul {
            list-style:none;
            padding:0;
            margin:0;
            .display-flex;
            li {
                text-align:center;
                .flex-basis(0);
                .flex-grow(1);
                i {
                    font-size:80px;
                }
                span {
                    display:block;
                    margin:20px 0 0;
                    .text-format(@circular, 24px, 32px, #444);
                }
            }
        }
    }
}

@media (max-width: @screen-md-max) {
	
}

@media (max-width: @screen-sm-max) {
	.allAbout {
		article {
			max-width:none;
			.flex(1 0 100%);
		}
	}
	section {
		&.figure {
			.icon-fish,
			.icon-lose,
			.icon-job,
			.icon-cultured,
			.icon-happy {
				max-height:500px;
				font-size:500px;
			}
		}
	}
	.theBest {
		.table {
			.col {
				&.first {
					em {
						padding:0 20px;
					}
				}
			}
		}
	}
}

@media (max-width: @screen-xs-max) {
	.allAbout {
		article {
			display:block;
			text-align:center;
			p {
				text-align:center;
				font-size:28px;
			}
			span {
				font-size:20px;
			}
			i[class*=icon-] {
				font-size:120px;
				margin:0 0 30px;
			}
		}
	}
	section {
		&.figure {
			.icon-fish,
			.icon-lose,
			.icon-job,
			.icon-cultured,
			.icon-happy {
				max-height:200px;
				font-size:250px;
				margin:0 0 50px;
			}
			.lead {
				padding-bottom:20px;
				p {
					font-size: 16px;
					line-height: 24px;
				}
			}
		}
	}
	.theBest {
		.lead.small {
			p {
				font-size: 16px;
				line-height: 24px;
			}
		}
		.table {
			font-size:12px;
			.col {
				&.first {
					max-width:30%;
					.flex(1 0 30%);
					b {
						padding:0 8px;
					}
					em {
						padding:0 8px;
						font-size:14px;
					}
				}
			}
			b {
				font-size:11px;
			}
			span, b, em, i {
				height:50px;
			}
			.icon-checkmark,
			.icon-close {
				height:50px;
				font-size:30px;
			}
		}
	}
	section.brand.header.talent {
		padding: 120px 0 20px;
		margin-top: -100px;
		height:auto;
	}
}
