@font-face {
	font-family: "Circular";
	font-display: swap;
	src:url("@{circular-font-path}/lineto-circular-book.eot");
	src:url("@{circular-font-path}/lineto-circular-book.eot?#iefix") format("embedded-opentype"),
	url("@{circular-font-path}/lineto-circular-book.woff2") format("woff2"),
	url("@{circular-font-path}/lineto-circular-book.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Circular";
	font-display: swap;
	src:url("@{circular-font-path}/lineto-circular-medium.eot");
	src:url("@{circular-font-path}/lineto-circular-medium.eot?#iefix") format("embedded-opentype"),
	url("@{circular-font-path}/lineto-circular-medium.woff2") format("woff2"),
	url("@{circular-font-path}/lineto-circular-medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Circular";
	font-display: swap;
	src:url("@{circular-font-path}/lineto-circular-bold.eot");
	src:url("@{circular-font-path}/lineto-circular-bold.eot?#iefix") format("embedded-opentype"),
	url("@{circular-font-path}/lineto-circular-bold.woff2") format("woff2"),
	url("@{circular-font-path}/lineto-circular-bold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	font-display: swap;
	src: url('@{proxima-font-path}/proxima-nova-regular.woff2') format('woff2'),
	url('@{proxima-font-path}/proxima-nova-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	font-display: swap;
	src: url('@{proxima-font-path}/proxima-nova-light.woff2') format('woff2'),
	url('@{proxima-font-path}/proxima-nova-light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
