.calculatorWrapper {
	min-height: 400px;
	padding: 0 0 50px 0;
	> div {
		display: none;
	}
	&.active {
		> div {
			display: block;
		}
	}
	.container {
		.title {
			text-align: center;
			text-transform: uppercase;
			margin: 0 0 20px;
			font-weight: bold;
			color: @blue;
		}
		article {
			> div {
				max-width: 900px;
				margin: 0 auto;
				padding: 0 15px;
				justify-content: center;
			}
			button {
				margin: 0 auto 50px;
				display: block;
			}
			&:after {
				content: '';
				display: table;
			}
		}
		.group {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			label {
				font-weight: bold;
				flex: 1 0 300px;
				max-width: 300px;
				text-align: right;
				padding: 0 15px;
				margin: 0;
			}
			> div {
				border-bottom: 1px solid @blue;
				padding: 0 15px;
				display: flex;
				align-items: center;
				width: 250px;
				em {
					font-style: normal;
				}
				input {
					flex: 1 0 auto;
				}
			}
			input {
				flex: 1 0 300px;
				max-width: 300px;
				background: 0 0;
				border: 0;
				margin: 0;
				height: 40px;
				padding: 0;
				&:focus {
					outline: none;
				}
			}
			span {
				flex: 1 0 auto;
				padding: 0 15px;
				color: #999;
			}
		}
	}
	.lead {
		h2 {
			max-width: 1100px;
			margin: 0 auto;
			padding: 0 15px;
			b {
				font-size: 36px;
				color: @blue;
				&:nth-of-type(2) {
					&:before {
						content: '';
						display: block;
					}
				}
			}
		}
	}
}
.brand.header.talent.calculatorHeader {
	display: flex;
	padding: 0;
	margin: -40px 0 0;
	> div {
		margin: 0 auto;
		padding: 0 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 300px;
		h1 {
			font-size: 36px;
			line-height: 36px;
			oveflow: visibile;
			margin: 0;
			padding: 0;
			&:before {
				display: none;
			}
			a {
				width: 200px;
				height: 60px;
				line-height: 60px;
				position: static;
				margin: 20px auto 10px;
				font-size: 24px;
				letter-spacing: -1px;
			}
		}
		img {
			display: block;
			width: 80px;
			height: 80px;
			margin: 20px auto 10px;
			border: 1px solid fadeout(@black, 50%);
			padding: 5px;
			background-color: @white;
		}
	}
}

@media (max-width: 767px) {
	.calculatorWrapper {
		.container {
			.group {
				display: block;
				label {
					padding: 0;
				}
				> div {
					width: auto;
					padding: 0;
				}
				span {
					padding: 0;
				}
			}
		}
		.lead {
			padding: 40px 0 50px;
			 h2 {
			 	font-size: 22px;
			 	b {
			 		font-size :24px;
			 		&:nth-of-type(2) {
			 			&:before {
			 				display: none;
			 			}
			 		}
			 	}
			 }
		}
	}
	.brand.header.talent {
		&.calculatorHeader {
			> div {
				img {
					width: 60px;
					height: 60px;
				}
				h1 {
					font-size: 26px;
					line-height: 28px;
					br {
						display: none;
					}
					a {
						height: 50px;
						width: 180px;
						line-height: 50px;
						font-size: 20px;
						font-weight: 600;
						letter-spacing: -.5px;
						margin: 20px auto 0;
					}
				}
			}
		}
	}
}
