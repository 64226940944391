section.brand.header2.header-beta {
	text-align: center;
	
	.title-wrapper {
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: space-between;
		opacity: 0;
		
		> div {
			flex: 1;
			
			&:first-child {
				padding-right: 30px;
				max-width: 450px;
			}
		}
		
		h1 {
			text-align: left;
		}
		
		.btn-white {
			margin: 0 0 5px;
		}
		
		small {
			text-align: left;
		}
		
		&.active {
			opacity: 1;
		}
	}
	
	.company {
		background: transparent none no-repeat center center/cover;
		text-align: center;
		color: @white;
		position: relative;
		flex-flow: row wrap;
		.flex-center;
		
		&.invision-video {
			position: relative;
			max-width: none;
			min-height: 388px;
			
			picture {
				position: absolute;
				min-width: 100%;
				max-width: 100%;
				max-height: 100%;
				overflow: hidden;
				
				img,
				source {
					min-width: 100%;
					max-width: 100%;
				}
			}
		}
		
		&.active {
			.animation(fadein .5s ease forwards);
		}
		
		h1 {
			max-width: 860px;
			margin: 0 auto;
			font: 600 50px/60px @circular;
			letter-spacing: -.5px;
		}
		
		p {
			font: 500 15px/21px @circular;
			text-transform: uppercase;
			color: fadeout(@white, 30%);
		}
		
		button {
			border: 0;
			background-color: @white;
			width: 40px;
			height: 40px;
			margin: -20px 0 0 -20px;
			.border-radius(50%);
			.flex-center;
			.transition(all .3s ease);
			.transform-origin(center);
			.position(absolute, 50%, 0, 0, 50%);
			
			&:before {
				content: '';
				display: block;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-left: 8px solid @black;
				margin-left: 2px;
			}
			
			&:hover {
				.transform(scale3d(1.3, 1.3, 1.3));
			}
			
			&:focus,
			&:active {
				outline: none;
			}
			
			&.hiddenPlay {
				opacity: 0;
				pointer-events: none;
			}
		}
		
		.iframe {
			display: flex;
			background-color: fadeout(@black, 50%);
			opacity: 0;
			pointer-events: none;
			.flex-center;
			.transition(all .3s ease);
			
			iframe {
				display: block;
				margin: 0 auto;
				padding: 20px 0;
				min-height: 100%;
				min-width: 50vw;
			}
			
			video {
				display: block;
				flex: 1;
				height: auto;
				max-width: 100%;
				position: relative;
				z-index: 1;
			}
			
			.icon-close {
				cursor: pointer;
				font-size: 32px;
				.position(absolute, 20px, 20px, auto, auto, 10);
			}
			
			&.active {
				opacity: 1;
				pointer-events: auto;
			}
		}
		
		.author {
			margin: 10px 0 0;
			font: 500 14px/21px @circular;
			text-transform: uppercase;
			color: fadeout(@white, 30%);
			text-align: left;
			display: block;
			.position(absolute, 100%, auto, auto, 0);
			
			img {
				margin: 0 10px 0 0;
				display: block;
				width: auto;
				height: auto;
				max-height: 50px;
			}
			
			span {
				display: block;
				text-align: left;
				font-size: 14px;
				font-weight: normal;
			}
			
			b {
				display: block;
				color: @white;
				font-size: 18px;
			}
		}
	}
	
	.icon-juul {
		font-size: 6vw;
		line-height: 4vw;
		overflow: visible;
	}
	
	.btn-white.btn-effect {
		max-width: none;
		display: inline-flex;
	}
}

@media (max-width: 1199px) {
	section.brand.header2.header-beta {
		.title-wrapper {
			h1 {
				font-size: 41px;
				line-height: 46px;
			}
		}
		
		.company {
			&.invision-video {
				min-height: 275px;
			}
		}
	}
}

@media (max-width: 991px) {
	section.brand.header2.header-beta {
		.title-wrapper {
			h1 {
				font-size: 35px;
				line-height: 41px;
			}
			
			p {
				margin: 0 0 20px;
			}
		}
		
		.company {
			&.invision-video {
				min-height: 194px;
			}
		}
	}
}

@media (max-width: 767px) {
	section.brand.header2.header-beta {
		.title-wrapper {
			flex-flow: column nowrap;
			
			> div {
				&:first-child {
					max-width: 100%;
				}
			}
			
			h1 {
				padding: 0;
			}
		}
		
		.company.invision-video {
			margin: 0 0 70px;
		}
		
		.icon-juul {
			font-size: 12vw;
		}
		
		small {
			font-size: 14px;
		}
	}
}
