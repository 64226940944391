iframe {
    display:block;
    width:900px;
    margin:0 auto;
}

.job-wrapper {
    .lead {
        h2 {
            text-align:left;
            margin:0;
            span {
                margin:0 0 0 8px;
                color:#999;
            }
        }
        h3 {
            text-align:left;
            margin:0 0 50px;
            text-transform:uppercase;
            letter-spacing:-.5px;
            font-size:16px;
            color:#999;
        }
        p {
            text-align:left;
            max-width:none;
            font-family:@nunito;
            padding:0;
            margin:0 0 50px;
            font-size:16px;
            line-height:24px;
        }
        .btn {
            display:flex;
            align-items:center;
            justify-content:center;
            width:180px;
            height:60px;
        }
    }
}