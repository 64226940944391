body {
	&.profile-page {
		padding: 0 0 50px;
		
		nav {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			height: 70px;
			z-index: 10;
			background-color: @blue;
			.display-flex;
			.align-items(center);
			.justify-content(center);
			
			.icon-logo-upstack {
				color: @white;
				font-size: 40px;
			}
			.container {
				margin: 0 auto;
				text-align: center;
				position: relative;
			}
			.btn {
				position: absolute;
				right: 0;
				width: auto;
				height: auto;
				padding: 10px 20px;
				top: 50%;
				letter-spacing: 0;
				.transform(translateY(-50%));
			}
		}
		
		header {
			position: static;
			margin: 70px 0 0;
			padding: 0;
			figure {
				height: 300px;
				background: fadeout(@blue, 40%) none no-repeat center -4vw/cover;
				padding: 0;
				margin: 0;
				position: relative;
				&:after {
					content: '';
					display: block;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					top: 60%;
					opacity: .7;
					background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
					background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
					background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
				}
			}
		}
		
		.profile-details {
			position: relative;
			z-index: 1;
			margin: 0 0 50px;
			.display-flex;
			figure {
				display: block;
				padding: 0;
				margin: -50px 30px 0 0;
				width: 200px;
				flex: 1 0 200px;
				max-width: 200px;
				height: 200px;
				background: #ccc none no-repeat center center/cover;
				border: 4px solid @white;
				border-radius: 4px;
				.box-shadow(0 3px 10px fadeout(@black, 87%));
			}
			h1 {
				margin: 20px 0 0;
				color: @blue;
				font: 500 28px/36px @circular;
				overflow: visible;
			}
			h3 {
				margin: 0 0 10px;
				color: #999;
				font: 400 22px/30px @circular;
				overflow: visible;
			}
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
				.display-flex;
				.flex-flow(row wrap);
				.align-items(center);
				li:not(.more) {
					margin: 0 5px 5px 0;
					position: relative;
					padding: 0 35px 0 15px;
					background-color: fadeout(@blue, 85%);
					color: #333;
					height: 30px;
					position: relative;
					font: 400 13px/1.4em @circular;
					.display-flex;
					.align-items(center);
					.border-radius(15px);
					b {
						display: block;
						width: 20px;
						height: 20px;
						line-height: 20px;
						text-align: center;
						background-color: fadeout(@blue, 20%);
						color:@white;
						.border-radius(50%);
						.position(absolute, 5px, 5px);
					}
				}
				li.more {
					color: @blue;
					height: 30px;
					line-height: 30px;
					margin: 0 0 5px 10px;
					cursor: pointer;
					i {
						position: relative;
						top: 2px;
					}
				}
			}
			.about {
				margin: 0 0 10px;
				.display-flex;
				span {
					margin: 0 15px 0 0;
					.display-flex;
					.align-items(center);
					i {
						color: #aaa;
						margin: 0 3px 0 0;
					}
					b {
						font: 400 13px/1.2em @circular;
						color: #7a7a7a;
					}
				}
			}
		}

		h2 {
			text-transform: uppercase;
			color: #333;
			font: 400 14px/1.2em @circular;
			margin: 30px 0;
			letter-spacing: .5px;
		}
		article {
			border: 1px solid #f0f0f0;
			padding: 25px 30px;
			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				.display-flex;
				.flex-flow(row wrap);
				li {
					max-width: 100%;
					margin: 0 0 15px;
					.flex(1 0 100%);
					.display-flex;
					.align-items(flex-start);
					&:last-child {
						margin: 0;
					}
				}
				label {
					font: 400 12px/19px @circular;
					color: #bbb;
					text-transform: uppercase;
					display: block;
					margin: 0;
					padding: 0;
					max-width: 120px;
					.flex(1 0 120px);
				}
				span {
					display: block;
					font: 400 14px/19px @circular;

				}
			}
			.job {
				margin: 0 -30px 20px;
				padding: 0 30px 20px;
				border-bottom: 1px solid #f0f0f0;
				&:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
					border: 0;
				}
				.company {
					label {
						a {
							color: @blue;
						}
					}
				}
				&.showcase {
					.company {
						figure {
							border: 0;
							height: 150px;
							max-width: 150px;
							.flex(1 0 150px);
							.box-shadow(none);
						}
						> div {
							.display-flex;
							.flex-flow(column nowrap);
							.justify-content(flex-start);
						}
						span {
							font-size: 16px;
						}
					}
				}
				&.education {
					.company {
						border: 0;
						.box-shadow(none);
						span {
							font-size: 16px;
						}
					}
				}
			}
			.company {
				margin: 0 0 15px;
				.display-flex;
				figure {
					display: block;
					padding: 0;
					margin: 0;
					background: #f0f0f0 none no-repeat center center/cover;
					height: 80px;
					max-width: 80px;
					border: 10px solid #f0f0f0;
					margin: 0 20px 0 0;
					.box-shadow(0 0 0 1px #ddd);
					.flex(1 0 100px);
				}
				label {
					display: block;
					margin: 0;
					color: #777;
					font: 400 14px/1.4em @circular;
					letter-spacing: .3px;
				}
				b {
					font-size: 20px;
					line-height: 1.4em;
					display: block;
					color: #333;
					small {
						margin: 0 0 0 10px;
						font-size: 14px;
						color: #999;
						font-weight: 400;
					}
				}
				span {
					display: block;
					font-size: 14px;
					line-height: 1.7em;
					color: #888;
					span {
						display: inline;
					}
					i {
						display: inline-block;
						width: 20px;
					}
					.icon-location-pin {
						font-size: 18px;
						position: relative;
						top: 2px;
					}
				}
			}
			.workflow {
				padding: 0 0 10px 100px;
				p {
					font: 400 15px/1.4em @circular;
					margin: 0 0 5px;
					color: #333;
					padding: 0 0 0 20px;
					position: relative;
					&:before {
						content: '';
						display: block;
						width: 8px;
						height: 8px;
						border: 2px solid @blue;
						.border-radius(50%);
						.position(absolute, 4px, auto, auto, 0);
					}
				}
			}
			.skills {
				padding: 0 0 0 100px;
				font-size: 14px;
				span {
					color: #999;
				}
				b {
					font-weight: 400;
					font-size: 14px;
					color: @blue;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	body {
		&.profile-page {
			header {
				figure {
					background: fadeout(@blue, 40%) none no-repeat center top/cover;
				}
			}
			.profile-details {
				display:block;
				figure {
					margin: -80px 0 0;
					width: 160px;
					height: 160px;
				}
			}
			article {
				padding: 15px;
				.company {
					figure {
						max-width: 60px;
						height: 60px;
						margin: 0 10px 0 0;
					}
					b {
						font-size: 18px;
						small {
							display: block;
							margin: 0;
						}
					}
					> div {
						> span {
							margin: 0 0 0 -70px;
						}
					}
				}
				.workflow,
				.skills {
					padding-left :0;
				}
				.job {
					&.showcase {
						.company {
							figure {
								max-width: 80px;
								height: 80px;
							}
							span {
								margin: 0;
							}
						}
					}
				}
			}
			nav {
				.container {
					text-align: left;
					max-width: 100%;
					.flex(1 0 100%);
				}
				.btn {
					right: 10px;
					padding: 10px;
					font-size: 20px;
				}
			}
		}
	}
}
