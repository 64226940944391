.brand.header2.header-beta {
	small {
		display: block;
		text-align: center;
		margin: 0 0 30px;
		font: 400 16px/21px @nunito;
	}
	.btn-white {
		margin: 0 auto 5px;
		padding: 0 30px;
		width: auto;
		max-width: 240px;
		&.btn-effect {
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				position: relative;
				pointer-events: none;
				color: @white;
				font-family: @circular;
			}
			&:before {
				background: radial-gradient(circle closest-side, @blue, transparent);
			}
		}
	}
	h1 {
		margin: 50px auto 0;
		max-width: 1200px;
	}
	p {
		margin: 0 auto 50px;
		font-weight: 600;
	}
	h1, p {
		text-align: center;
		font-size: 50px;
		line-height: 60px;
		height: auto;
	}
}

.main.beta-wrapper {
	background-color: @white;
	section article p {
		color: @black;
	}
}

.main {
	section.talents.beta2 {
		.btn-outline {
			display: block;
			margin: 0 auto 100px;
			border: 1px solid @blue;
			width: auto;
			padding: 0 20px;
			border-radius: 0;
		}
		.learn-more {
			text-align: center;
			.btn-outline {
				display: inline-flex;
				align-items: center;
				line-height: 68px;
			}
		}
	}
}

.backed.howItWorks {
	margin: 0;
	padding: 0 0 100px;
	.btn-effect {
		margin: 0 auto 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: @green;
		span {
			color: @white;
			position: relative;
  			pointer-events: none;
		}
		&:before {
		  background: radial-gradient(circle closest-side, @blue, transparent);
		}
		+ small {
			display: block;
			text-align: center;
			color: @blue;
			font-size: 14px;
		}
	}
}

.trusted.beta {
	background-color: @white;
	padding: 70px 0 50px;
}

.allInOne {
	.lead {
		padding: 50px 0 0;
		h2 {
			max-width: 880px;
			margin: 0 auto;
		}
	}
}

.assets {
	img.mpts {
		display: block;
		max-width: 880px;
		width: 100%;
		height: auto;
	}
	picture.mpts {
		img,
		source {
			display: block;
			max-width: 880px;
			width: 100%;
			height: auto;
		}
	}
	&.beta {
		i {
			font-size:80px;
		}
	}
}

@media (max-width: 767px) {
	.brand.header2.header-beta {
		padding: 0 0 90px;
		margin: 0;
		min-height: 0;
		> div {
			> div {
				text-align: center;
				padding: 30px 15px 20px;
			}
		}
		h1, p {
			display: inline;
			margin: 0 5px 0 0;
			padding: 0;
			font-size: 28px;
			line-height: 34px;
		}
	}
	.assets {
		.row {
			margin: 0;
			> div {
				float: none;
				width: auto;
				margin: 0 0 40px;
				p {
					height: auto;
					margin: 10px 0;
				}
			}
		}
		img.mpts {
			margin: 0 0 40px;
		}
	}
}
