.members {
    margin:100px auto;
    ul {
        list-style:none;
        margin:0;
        padding:0;
        .member {
            margin:0 0 70px;
            .display-flex;

            .img {
                margin-right:50px;
                position:relative;
                img {
                    width:200px;
                    height:200px;
                    object-fit: cover;
                    .border-radius(50%);
                }
            }
            h3 {
                margin:0;
                .text-format(@circular, 28px, 40px, darken(@blue, 30%), 500);
            }
            h4 {
                margin:0;
                .text-format(@circular, 18px, 32px, #999, 400, normal, uppercase);
            }
            p {
                margin:15px 0 0;
                .text-format(@circular, 20px, 30px, @black, 400);
            }

            &[data=active] {
                .img {
                    opacity:1;
                    .transform(scale3d(1, 1, 1));
                }
                .about {
                    opacity:1;
                    .translate3d(0, 0, 0);
                }
            }
        }
    }
}

@media (max-width: @screen-md-max) {
	
}

@media (max-width: @screen-sm-max) {
	
}

@media (max-width: @screen-xs-max) {
	.members {
		ul {
			.member {
				display:block;
				text-align:center;
				.img {
					margin:0 0 15px;
					img {
						margin:0 auto;
					}
				}
				h3 { font-size:32px; }
				h4,
				p { font-size:20px; }
			}
		}
	}
}
